import { normalize } from 'normalizr';

import { ThunkActionCreator } from 'app/store';
import { getSelectedOrganizationIdCookies, setToken } from 'utils/cookies';
import { post } from 'utils/api';
import { fetchUserDone } from 'slices/user';
import { selectOrganization } from 'slices/organizations';
import { addRecords } from 'slices/entities';
import { organizationSchema } from 'models/organization';

interface LoginPayload {
  username: string;
  password: string;
}

export const login: ThunkActionCreator<{
  username: string;
  password: string;
}> = ({ username, password }: LoginPayload) => async dispatch => {
  const res = await post('/map/auth', {
    email: username,
    password,
    organization_id: getSelectedOrganizationIdCookies(),
  });

  if (res.status >= 400) {
    throw res.status;
  } else {
    const { user, organization } = await res.json();
    setToken(user.token);

    dispatch(addRecords(normalize(organization, organizationSchema)));
    dispatch(selectOrganization(organization.id));
    dispatch(fetchUserDone(user));
  }
};
