import jsonToFormData from 'json-form-data';
import { normalize } from 'normalizr';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash-es';

import { ThunkActionCreator } from 'app/store';
import { Course, courseSchema, QuipperSchoolCourseMeta } from 'models/course';
import { VideoPresenter, videoPresenterSchema } from 'models/videoPresenter';
import { addRecords, deleteRecords } from 'slices/entities';
import { selectOrganization } from 'slices/organizations';
import { deleteResource, get, post, put } from 'utils/api';
import { getSelectedOrganizationIdCookies } from 'utils/cookies';
import { sortBasicGradeLevels } from 'utils/sort';
import { buildUrlWithCourseFiltersQuery, FetchCoursesQuery } from 'utils/url';
import { Subject } from 'models/subject';
import { Grade, isCustomGrade, isSystemGrade } from 'models/grades';
import { CourseTag, OtherCourseTag } from 'models/courseTag';
import { User } from 'models/user';

interface CoursesState {
  error?: string;
  loading: boolean;
}

const initialState: CoursesState = {
  loading: false,
};

const slice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    fetchCourseFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchCourseDone(state) {
      state.loading = false;
    },
    fetchCoursesStart(state) {
      state.loading = true;
      state.error = undefined;
    },
  },
});

export const {
  fetchCourseFailed,
  fetchCourseDone,
  fetchCoursesStart,
} = slice.actions;

export const fetchCourse: ThunkActionCreator<{
  id: string;
  setOrg?: boolean;
}> = ({ id, setOrg = false }) => async dispatch => {
  const res = await get(`/content/courses/${id}`);

  if (res.status >= 400) {
    throw res.status;
  } else {
    const course: Course = await res.json();
    const normalizedData = normalize<Course>(course, courseSchema);
    dispatch(addRecords(normalizedData));

    const orgId = course.organization_id;

    if (setOrg && orgId && orgId !== getSelectedOrganizationIdCookies()) {
      dispatch(selectOrganization(course.organization_id));
    }

    dispatch(fetchCourseDone());
  }
};

export const fetchCourseOutline: ThunkActionCreator<{
  id: string;
}> = ({ id }) => async dispatch => {
  const res = await get(`/content/courses/${id}/show_tree`);

  if (res.status >= 400) {
    throw res.status;
  }

  const course: Course = await res.json();
  const normalizedData = normalize<Course>(course, courseSchema);
  dispatch(addRecords(normalizedData));

  return course;
};

export const fetchCourses: ThunkActionCreator<{
  organizationId: string;
  query?: FetchCoursesQuery;
}> = ({ organizationId, query }) => async dispatch => {
  const res = await get(
    buildUrlWithCourseFiltersQuery(
      `/content/organizations/${organizationId}/courses`,
      query,
    ),
  );

  if (res.status >= 400) {
    throw res.status;
  }

  const courses: Course[] = await res.json();

  const normalizedData = normalize<Course>(courses, [courseSchema]);

  dispatch(
    addRecords({
      ...normalizedData,
      options: { concatKeys: ['bundles'] },
    }),
  );

  return {
    organizationId,
    count: res.headers.get('X-Quipper-Count'),
    pages: res.headers.get('X-Quipper-Pages'),
    ids: courses.map(c => c.id),
  };
};

const removeTemporaryIds = (tags: Array<Subject | CourseTag> = []) => {
  return tags.map(withId => {
    // name is used as a temporary id for new subject/course tag
    if (withId.id === withId.name) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...withoutId } = withId;
      return withoutId;
    } else {
      return withId;
    }
  });
};

const buildPayload = (payload: CreateCoursePayload | CoursePayload) => {
  const {
    preview_video_data,
    meta,
    grades,
    otherTags,
    subjects: _subjects,
    isCourseLimited,
    limitedTo,
    ...rest
  } = payload;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const course: any = rest;

  if (rest.system) {
    course.preview_video_data = preview_video_data;
  }

  const systemGrades = grades.filter(isSystemGrade);
  const customGrades = grades.filter(isCustomGrade);

  if (meta) {
    // Remove assessmentOrTryout since it's only used to toggle showing time
    // limit. When it is false, set time limit to empty strings to clear its
    // value
    const { assessmentOrTryout, time_limit } = meta;

    const formattedMeta = {
      level: sortBasicGradeLevels(systemGrades).join(','),
      ...(assessmentOrTryout && { time_limit }),
    };

    if (!isEmpty(formattedMeta)) {
      course.meta = formattedMeta;
    }
  }

  const subjects = removeTemporaryIds(_subjects);
  const courseTags = removeTemporaryIds([...customGrades, ...otherTags]);

  const shareSettings = {
    // true when course can be accessed by all members of the org (public) or by
    // a limited number of users (including the author)
    //
    // false when course is only author and org admins can access (private)
    enabled: isCourseLimited && limitedTo.length === 0 ? false : true,
    // set user_ids to [] when course is shared to all members of the org (public)
    user_ids: isCourseLimited ? limitedTo.map(user => user.id) : [],
  };

  return jsonToFormData(
    {
      course,
      ...(courseTags.length > 0 && { course_tags: courseTags }),
      ...(subjects.length > 0 && { subjects }),
      share_settings: shareSettings,
    },
    { showLeafArrayIndexes: true },
  );
};

interface CoursePayload {
  name: string;
  description: string;
  icon: File | string;
  subjects: Subject[];
  system: string;
  meta: QuipperSchoolCourseMeta;
  preview_video_data?: {
    url: string;
    description: string;
  };
  grades: Grade[];
  otherTags: OtherCourseTag[];
  isCourseLimited: boolean;
  limitedTo: User[];
}

interface CreateCoursePayload extends CoursePayload {
  coursable_id: string;
  coursable_type: string;
  assesssment_course?: {
    active: boolean;
  };
}

export const createCourse: ThunkActionCreator<CoursePayload & {
  organizationId: string;
}> = ({ organizationId, ...payload }) => async dispatch => {
  const res = await post(
    `/content/courses`,
    buildPayload({
      coursable_id: organizationId,
      coursable_type: 'Organization',
      ...payload,
    }),
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const newCourse: Course = await res.json();
    const normalizedData = normalize(newCourse, courseSchema);

    dispatch(addRecords(normalizedData));
    return newCourse.id;
  }
};

interface UpdateCoursePayload extends CoursePayload {
  id: string;
}

export const updateCourse: ThunkActionCreator<UpdateCoursePayload> = ({
  id,
  ...payload
}) => async dispatch => {
  const res = await put(`/content/courses/${id}`, buildPayload(payload));

  if (res.status >= 400) {
    throw res.status;
  } else {
    const updatedCourse: Course = await res.json();
    const normalizedData = normalize(updatedCourse, courseSchema);
    dispatch(addRecords(normalizedData));
  }
};

export const reorderCourseBundles: ThunkActionCreator<{
  courseId: string;
  orderedBundleIds: string[];
}> = ({ courseId, orderedBundleIds }) => async _ => {
  const res = await put(`/content/bundles/reorder.json`, {
    course_id: courseId,
    order: orderedBundleIds,
  });

  if (res.status >= 400) {
    throw res.status;
  }
};

export const deleteCourse: ThunkActionCreator<{ courseId: string }> = ({
  courseId,
}) => async (dispatch, getState) => {
  const res = await deleteResource(`/content/courses/${courseId}`);

  if (res.status >= 400) {
    throw res.status;
  } else {
    const course = getState().entities.courses.byId[courseId];
    const normalizedData = normalize(course, courseSchema);

    dispatch(deleteRecords(normalizedData));
  }
};

export const fetchQuipperCourses: ThunkActionCreator<{
  courseSetId: string;
  query?: FetchCoursesQuery & { course_subset_id: string };
}> = ({ courseSetId, query }) => async (dispatch, getState) => {
  const organizationId = getState().organizations.selectedId;
  const res = await get(
    buildUrlWithCourseFiltersQuery(
      `/importing/organizations/${organizationId}/courses`,
      query,
    ),
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const courses: Course[] = await res.json();
    const normalizedData = normalize(courses, [courseSchema]);
    dispatch(addRecords(normalizedData));

    return {
      courseSetId,
      count: res.headers.get('X-Quipper-Count'),
      pages: res.headers.get('X-Quipper-Pages'),
      ids: courses.map(c => c.id),
    };
  }
};

export interface VideoPresenterPayload {
  id?: string;
  name: string;
  image: string | File;
  courseId: string;
}

export const addVideoPresenter: ThunkActionCreator<VideoPresenterPayload> = ({
  courseId,
  name,
  image,
}) => async dispatch => {
  const payload = {
    name,
    image,
  };

  const res = await post(
    `/content/courses/${courseId}/video_presenters`,
    jsonToFormData(payload),
  );

  if (res.status >= 400) {
    throw res.status;
  }

  const videoPresenter: VideoPresenter = await res.json();
  const normalizedData = normalize(videoPresenter, videoPresenterSchema);
  dispatch(addRecords(normalizedData));

  return videoPresenter.id;
};

export const updateVideoPresenter: ThunkActionCreator<VideoPresenterPayload> = ({
  id,
  name,
  image,
  courseId,
}) => async dispatch => {
  const payload = {
    name,
    // The image will only have changed if it is a file. If it is a string then
    // it must be a URL of the existing picture so don't include it
    ...(image instanceof File && { image }),
  };

  const res = await put(
    `/content/courses/${courseId}/video_presenters/${id}`,
    jsonToFormData(payload),
  );

  if (res.status >= 400) {
    throw res.status;
  }

  const videoPresenter: VideoPresenter = await res.json();
  const normalizedData = normalize(videoPresenter, videoPresenterSchema);
  dispatch(addRecords(normalizedData));
};

export const fetchVideoPresenters: ThunkActionCreator<{ courseId: string }> = ({
  courseId,
}) => async dispatch => {
  const res = await get(`/content/courses/${courseId}/video_presenters`);

  if (res.status >= 400) {
    throw res.status;
  }

  const videoPresenters: VideoPresenter[] = await res.json();
  const normalizedData = normalize(videoPresenters, [videoPresenterSchema]);
  dispatch(addRecords(normalizedData));
};

export interface DeleteVideoPresenterPayload {
  id: string;
  courseId: string;
}

export const deleteVideoPresenter: ThunkActionCreator<DeleteVideoPresenterPayload> = ({
  courseId,
  id,
}) => async (dispatch, getState) => {
  const res = await deleteResource(
    `/content/courses/${courseId}/video_presenters/${id}`,
  );

  if (res.status >= 400) {
    throw res.status;
  }

  const videoPresenter = getState().entities.videoPresenters.byId[id];
  const normalizedData = normalize(videoPresenter, videoPresenterSchema);
  await dispatch(deleteRecords(normalizedData));
};

export default slice.reducer;
