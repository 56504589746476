import { createSelector } from 'reselect';
import { sortBy } from 'lodash-es';

import { RootState } from 'app/rootReducer';
import { SectionContentType, Section } from 'models/section';
import { squashSections, squashSectionsHtml } from 'utils/content';
import { getTopicEntitySlice } from 'selectors/topics';

export const getQuestionEntitySlice = (state: RootState) =>
  state.entities.questions;

export const getQuestions = (topicId: string) =>
  createSelector(
    getTopicEntitySlice,
    getQuestionEntitySlice,
    ({ byId: topicsById }, { byId: questionsById }) => {
      const topic = topicsById[topicId];

      if (!topic) return [];

      const questions = Object.values(questionsById).filter(question =>
        topic.questions?.includes(question.id),
      );
      return sortBy(questions, 'position');
    },
  );

export const getQuestion = (id: string) =>
  createSelector(getQuestionEntitySlice, ({ byId: questions }) => {
    const question = questions[id];

    if (!question) {
      return;
    }

    const { sections = [], explanation, hint } = question;

    const contentSections = sections.filter(
      s => s.section_content_type !== SectionContentType.Passage,
    );
    const explanationSections = (explanation && explanation.sections) || [];
    const hintSections = (hint && hint.sections) || [];
    const passage = sections.find(
      (s: Section) => s.section_content_type === SectionContentType.Passage,
    );

    return {
      ...question,
      content: squashSections(contentSections),
      hintContent: squashSections(hintSections),
      explanationContent: squashSections(explanationSections),
      passage_id: passage && passage.section_content_id,
    };
  });

export const getQuestionForPreview = (id: string) =>
  createSelector(getQuestionEntitySlice, ({ byId: questions }) => {
    const question = questions[id];

    const { sections = [], explanation, hint } = question;

    const contentSections = sections.filter(
      s => s.section_content_type !== SectionContentType.Passage,
    );
    const explanationSections = (explanation && explanation.sections) || [];
    const hintSections = (hint && hint.sections) || [];
    const passage = sections.find(
      (s: Section) => s.section_content_type === SectionContentType.Passage,
    );

    return {
      ...question,
      content: squashSectionsHtml(contentSections),
      hintContent: squashSectionsHtml(hintSections),
      explanationContent: squashSectionsHtml(explanationSections),
      passage_id: passage && passage.section_content_id,
    };
  });

export const getQuestionLastSavedAt = createSelector(
  getQuestionEntitySlice,
  (_: RootState, id: string) => id,
  ({ byId: questions }, id) => {
    const question = questions[id];

    if (!question) {
      return;
    }

    return question.last_saved_at;
  },
);
