import { schema } from 'normalizr';

export interface VideoPresenter {
  id: string;
  name: string;
  picture_url?: string;
  course_id: string;
}

export const videoPresenterSchema = new schema.Entity('videoPresenters');
