import React, { useState } from 'react';
import classNames from 'classnames';

import Tooltip from 'components/atoms/tooltip/Tooltip';
import './MultilineTruncate.css';

interface Props {
  children: React.ReactNode;
  className?: string;
  tooltipText?: string;
}

const MultilineTruncate = ({ className, tooltipText, children }: Props) => {
  const [willUseTooltip, setWillUseTooltip] = useState(false);

  const body = (
    <div
      ref={(el: HTMLDivElement) =>
        el?.scrollHeight > el?.clientHeight && setWillUseTooltip(true)
      }
      className={classNames(
        'MultilineTruncate overflow-hidden break-all',
        className,
      )}
    >
      {children}
    </div>
  );

  if (tooltipText && willUseTooltip) {
    return <Tooltip text={tooltipText}>{body}</Tooltip>;
  }

  return body;
};

export default MultilineTruncate;
