import React from 'react';
import { useIntl } from 'react-intl';

const useBeforeUnload = ({ condition }: { condition: boolean }) => {
  const { formatMessage: f } = useIntl();

  React.useEffect(() => {
    if (condition && !window.onbeforeunload) {
      window.onbeforeunload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        // We provide a message for backwards compatibility
        // but most modern browsers will ignore the value
        // and display a generic message
        // ref: https://stackoverflow.com/a/38880926
        e.returnValue = f({ id: 'form.unsavedChanges.description' });
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [condition, f]);
};

export default useBeforeUnload;
