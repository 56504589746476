import React, { useEffect, useMemo } from 'react';
import { debounce } from 'lodash-es';

const useAutoSave = (
  shouldSave: boolean,
  onSave: (p?: React.SyntheticEvent, b?: boolean) => void,
) => {
  const debouncedSave = useMemo(
    () =>
      debounce(() => {
        onSave(undefined, true);
      }, 3000),
    [onSave],
  );

  useEffect(() => {
    if (shouldSave) {
      debouncedSave();
    }
  }, [shouldSave, debouncedSave]);

  // Cleanup for stopping the call of save
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);
};

export default useAutoSave;
