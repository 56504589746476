import { GradeCourseTag } from 'models/courseTag';

export type Grade = string | GradeCourseTag;

export const gradesK3 = ['G3', 'G2', 'G1', 'Kinder'];
export const grades = [
  'G12',
  'G11',
  'G10',
  'G9',
  'G8',
  'G7',
  'G6',
  'G5',
  'G4',
  ...gradesK3,
];

export const isSystemGrade = (
  grade: string | GradeCourseTag,
): grade is string => {
  return typeof grade === 'string';
};

export const isCustomGrade = (
  grade: string | GradeCourseTag,
): grade is GradeCourseTag => {
  return (
    typeof grade === 'object' &&
    grade.id !== undefined &&
    grade.name !== undefined &&
    grade.type === 'grade'
  );
};
