import classNames from 'classnames';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import { LinkTextButton } from 'components/atoms/Button';
import Tooltip from 'components/atoms/tooltip/Tooltip';
import { ReactComponent as ForwardIcon } from 'assets/icons/forward.svg';

import './Sidebar.css';

interface Props {
  id?: string;
  headerLinkUrl?: string;
  headerLinkText?: string;
  headerLinkElement?: React.ReactNode;
  children: (isOpen: boolean) => React.ReactNode;
}

const Sidebar = ({
  id = 'sidebar',
  children,
  headerLinkUrl,
  headerLinkText,
  headerLinkElement,
}: Props) => {
  const { formatMessage: f } = useIntl();

  const [isOpen, setIsOpen] = useState(true);

  const [toggleButtonTooltipText, setToggleButtonTooltipText] = useState(
    f({ id: 'sidebar.close' }),
  );

  const renderHeaderElement = () => {
    if (headerLinkElement) return headerLinkElement;

    if (headerLinkUrl) {
      return (
        <LinkTextButton small to={headerLinkUrl}>
          {headerLinkText}
        </LinkTextButton>
      );
    } else {
      return <span className="text-12 font-bold">{headerLinkText}</span>;
    }
  };

  return (
    <CSSTransition
      in={isOpen}
      timeout={200}
      classNames="Sidebar"
      onEntered={() => setToggleButtonTooltipText(f({ id: 'sidebar.close' }))}
      onExited={() => setToggleButtonTooltipText(f({ id: 'sidebar.open' }))}
    >
      <nav
        className={classNames(
          'Sidebar fixed flex flex-col bg-grey-minus3 w-280',
          isOpen && 'isOpen',
        )}
        data-testid="sidebar"
        id={id}
      >
        <header
          className={classNames('flex items-center justify-between pl-16')}
        >
          {renderHeaderElement()}

          <button
            onClick={() => setIsOpen(prev => !prev)}
            className="p-8 focus:outline-none"
          >
            <Tooltip
              text={toggleButtonTooltipText}
              placement={isOpen ? 'bottom' : 'bottomLeft'}
            >
              <ForwardIcon
                height="24"
                width="24"
                className={classNames(
                  'text-grey cursor-pointer fill-current',
                  isOpen && 'rotate-180',
                )}
                data-testid="toggle-sidebar-icon"
              />
            </Tooltip>
          </button>
        </header>
        {children(isOpen)}
      </nav>
    </CSSTransition>
  );
};

export default Sidebar;
