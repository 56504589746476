import { normalize } from 'normalizr';

import { ThunkActionCreator } from 'app/store';
import { post, get, put, deleteResource } from 'utils/api';
import { bundleSchema, Bundle } from 'models/bundle';
import { addRecords, deleteRecords } from 'slices/entities';
import { selectOrganization } from 'slices/organizations';
import { getSelectedOrganizationIdCookies } from 'utils/cookies';

interface BundlePayload {
  name: string;
  description: string;
}

interface CreateBundlePayload extends BundlePayload {
  courseId: string;
}

export const createBundle: ThunkActionCreator<CreateBundlePayload> = ({
  courseId,
  ...values
}) => async dispatch => {
  const res = await post(`/content/bundles`, {
    course_id: courseId,
    ...values,
  });

  if (res.status >= 400) {
    throw res.status;
  } else {
    const bundle: Bundle = await res.json();
    const normalizedData = normalize(bundle, bundleSchema);
    dispatch(addRecords(normalizedData));
    return bundle.id;
  }
};

interface UpdateBundlePayload extends BundlePayload {
  id: string;
}

export const updateBundle: ThunkActionCreator<UpdateBundlePayload> = ({
  id,
  ...values
}) => async dispatch => {
  const res = await put(`/content/bundles/${id}`, values);

  if (res.status >= 400) {
    throw res.status;
  } else {
    const bundle: Bundle = await res.json();
    const normalizedData = normalize(bundle, bundleSchema);
    dispatch(addRecords(normalizedData));
  }
};

export const fetchBundle: ThunkActionCreator<{
  id: string;
  setOrg?: boolean;
}> = ({ id, setOrg = false }) => async dispatch => {
  const res = await get(`/content/bundles/${id}`);

  if (res.status >= 400) {
    throw res.status;
  } else {
    const bundle: Bundle = await res.json();
    const normalizedData = normalize(bundle, bundleSchema);
    dispatch(addRecords(normalizedData));

    const orgId = bundle.organization_id;

    if (setOrg && orgId && orgId !== getSelectedOrganizationIdCookies()) {
      dispatch(selectOrganization(bundle.organization_id));
    }
  }
};

export const deleteBundle: ThunkActionCreator<string> = bundleId => async (
  dispatch,
  getState,
) => {
  const res = await deleteResource(`/content/bundles/${bundleId}`);

  if (res.status >= 400) {
    throw res.status;
  } else {
    const bundle = getState().entities.bundles.byId[bundleId];
    const normalizedData = normalize(bundle, bundleSchema);

    await dispatch(deleteRecords(normalizedData));
  }
};

export const fetchQuipperBundle: ThunkActionCreator<string> = id => async (
  dispatch,
  getState,
) => {
  // The endpoint requires a bundle guid and doesn't return a
  // valid response when given just an id
  const bundleGuid = getState().entities.bundles.byId[id].guid;
  const res = await get(
    `/map/topics?from_quipper_courses=true&bundle_id=${bundleGuid}`,
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const topics = await res.json();
    const bundle = {
      id,
      topics,
    };
    const normalizedData = normalize(bundle, bundleSchema);
    dispatch(addRecords(normalizedData));
  }
};
