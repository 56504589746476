import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

type Type = 'success' | 'danger';

const Alert = ({
  className,
  children,
  type,
  ...props
}: { type?: Type } & HTMLAttributes<HTMLParagraphElement>) => {
  const defaultClasses = 'py-12 px-16 font-bold border rounded';

  let typeClasses = 'text-danger border-danger bg-danger-minus2';

  if (type === 'success') {
    typeClasses = 'text-success border-success bg-success-minus2';
  }

  return (
    <div
      role="alert"
      className={classNames(defaultClasses, typeClasses, className)}
      {...props}
    >
      {children}
    </div>
  );
};

export default Alert;
