import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { MenuItemProps } from 'rc-menu/lib/MenuItem';
import { useSelector } from 'react-redux';

import Dropdown from 'components/atoms/Dropdown/Dropdown';
import MenuItem from 'components/atoms/Dropdown/MenuItem';
import Separator from 'components/atoms/Separator';
import AutoSaveToggle from 'components/molecules/AutoSaveToggle';
import { useAuth } from 'context/AuthContext';
import { useUser } from 'context/UserContext';
import { ReactComponent as NewTabIcon } from 'assets/icons/new-tab.svg';
import { ReactComponent as AvatarDefaultIcon } from 'assets/icons/avatar-default.svg';
import config from 'appConfig';
import { getSelectedOrganization } from 'selectors/organizations';

const Settings = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Separator />
      <p className="text-grey text-12 font-bold cursor-default ml-16 mt-8">
        {children}
      </p>
    </>
  );
};

const ExternalLink = ({
  url,
  children,
  ...props
}: {
  url: string;
  children: React.ReactNode;
} & MenuItemProps) => {
  return (
    <MenuItem
      href={url}
      target="_blank"
      linkClassName="flex justify-between items-center"
      {...props}
    >
      {children}
      <NewTabIcon className="inline fill-current h-16" />
    </MenuItem>
  );
};

const AvatarMenu = ({
  className,
  showLinks,
}: {
  className?: string;
  showLinks?: boolean;
}) => {
  const { formatMessage: f } = useIntl();
  const { logout } = useAuth();
  const user = useUser();
  const organization = useSelector(getSelectedOrganization);

  const menuItems = [
    <MenuItem className="cursor-pointer" key="logout" onClick={logout}>
      {f({ id: 'avatarMenu.logout' })}
    </MenuItem>,
  ];

  if (organization?.enable_autosave) {
    menuItems.unshift(
      <Settings key="settingsLabel">
        {f({ id: 'avatarMenu.settings' })}
      </Settings>,
      <AutoSaveToggle key="autoSaveToggle" />,
    );
  }

  if (showLinks) {
    menuItems.unshift(
      <ExternalLink
        key="qlink"
        url={`${config.REACT_APP_QLINK_URL}/organizations/${organization?.id}`}
      >
        {f({ id: 'avatarMenu.qlink' })}
      </ExternalLink>,
      <ExternalLink key="qlearn" url={config.REACT_APP_QLEARN_URL}>
        {f({ id: 'avatarMenu.qlearn' })}
      </ExternalLink>,
    );
  }

  return (
    <Dropdown
      className="w-176 fixed"
      menuItems={menuItems}
      placement="bottomRight"
    >
      <button
        className={classNames(
          'Icon h-40 w-40 bg-grey-minus2 focus:outline-none',
          className,
        )}
        data-testid="avatar-menu"
      >
        {user && user.profile_image_url ? (
          <img
            className="rounded-full"
            src={user.profile_image_url}
            alt="Avatar"
          />
        ) : (
          <AvatarDefaultIcon height="24" className="m-auto" />
        )}
      </button>
    </Dropdown>
  );
};

export default AvatarMenu;
