import { useRef, useEffect } from 'react';

// Use this hook to save a resource's parent id. Useful when deleting a resource
// and then navigating back to its parents page.

const useSaveId = (id: string) => {
  const idRef = useRef<string | undefined>();

  useEffect(() => {
    if (!idRef.current && id) {
      idRef.current = id;
    }
  }, [id]);

  return idRef.current;
};

export default useSaveId;
