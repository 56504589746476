import { combineReducers, PayloadAction } from '@reduxjs/toolkit';

import coursesReducer from 'slices/courses';
import topicsReducer from 'slices/topics';
import entitiesReducer from 'slices/entities';
import organizationsReducer from 'slices/organizations';
import userReducer from 'slices/user';

const appReducer = combineReducers({
  courses: coursesReducer,
  topics: topicsReducer,
  entities: entitiesReducer,
  organizations: organizationsReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState | undefined, action: PayloadAction) => {
  if (action.type === 'logout') {
    // reducers are supposed to return the initial state when they are called
    // with undefined, no matter the action
    // Ref: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
