import React, {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  RefObject,
} from 'react';
import classNames from 'classnames';

import './Input.css';

const defaultClasses = `
  Input
  border rounded px-16 py-12 placeholder-grey
  focus:outline-none 
`;

const focusDefaultClasses =
  'focus:border-primary-plus1 focus:shadow-input focus:bg-white';

const invalidClasses = `border-danger bg-danger-minus2`;

const getClasses = ({
  hasValue,
  disabled,
  invalid,
}: {
  hasValue: boolean;
  disabled?: boolean;
  invalid?: boolean;
}) => {
  const backgroundColorClass = hasValue ? 'bg-white' : 'bg-grey-minus3';

  const getAddedClasses = () => {
    if (disabled) {
      return 'bg-grey-minus2 border-grey-minus2';
    } else if (invalid) {
      return invalidClasses;
    } else {
      return `${backgroundColorClass} ${focusDefaultClasses} hover:border-primary-plus1 border-grey-minus1`;
    }
  };

  return classNames(defaultClasses, getAddedClasses());
};

export const Input = React.forwardRef(
  (
    {
      className,
      disabled,
      value,
      defaultValue,
      ...props
    }: InputHTMLAttributes<HTMLInputElement>,
    ref: RefObject<HTMLInputElement>,
  ) => {
    return (
      <input
        ref={ref}
        className={classNames(
          getClasses({ disabled, hasValue: !!(value || defaultValue) }),
          className,
        )}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        {...props}
      />
    );
  },
);

export const Textarea = React.forwardRef(
  (
    {
      className,
      disabled,
      invalid,
      ...props
    }: TextareaHTMLAttributes<HTMLTextAreaElement> & { invalid?: boolean },
    ref: RefObject<HTMLTextAreaElement>,
  ) => {
    const hasValue = !!(props.value || props.defaultValue);

    return (
      <textarea
        ref={ref}
        className={classNames(
          'resize-none',
          getClasses({ invalid, disabled, hasValue }),
          className,
        )}
        disabled={disabled}
        {...props}
      />
    );
  },
);
