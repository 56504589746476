import Cookies from 'js-cookie';

import { CountryCode, Organization } from 'models/organization';
import { SHARED_COOKIE_OPTIONS } from 'utils/cookies';

export enum Locale {
  Default = 'en-base',
  English = 'en',
  Indonesian = 'id',
  Filipino = 'fil',
}
export const Locales: Locale[] = [Locale.English, Locale.Indonesian];
interface LanguageDictionary {
  [index: string]: string;
}
export const languageMap: LanguageDictionary = {
  en: 'English',
  id: 'Bahasa Indonesia',
};

interface NestedTranslationsObject {
  [key: string]: NestedTranslationsObject | string | null;
}

export const flatten = (input: NestedTranslationsObject, prefix = '') =>
  Object.keys(input).reduce(
    (translations: NestedTranslationsObject, key: string) => {
      const prefixedKey = prefix ? `${prefix}.${key}` : key;
      const value = input[key];

      if (!value) {
        return translations;
      }

      if (typeof value === 'string') {
        Object.assign(translations, { [prefixedKey]: value });
      } else {
        Object.assign(translations, flatten(value, prefixedKey));
      }

      return translations;
    },
    {},
  ) as Record<string, string>;

const getTranslationsFor = (locale: Locale) => {
  return require(`translations/${locale}`);
};

export const getMessagesFor = (locale: Locale) => {
  return flatten(getTranslationsFor(locale));
};

export const isValidLocale = (locale: string): boolean => {
  return Locales.includes(locale as Locale);
};

export const getPathname = () => {
  return window.location.pathname;
};

export const getLocale = (): Locale => {
  const defLang = Locale.English;
  const urlLocale = getPathname().split('/')[1];
  const locale = Cookies.get('language') || urlLocale || defLang;

  if (isValidLocale(locale)) {
    return locale as Locale;
  } else {
    return defLang;
  }
};

export const getSegmentKeyByLocale = (enKey: string): string => {
  switch (getLocale()) {
    case 'id':
      return 'other';
    case 'en':
      return enKey;
    default:
      return enKey;
  }
};

export const getMessages = (isTesting = process.env.NODE_ENV === 'test') => {
  if (isTesting) {
    return getMessagesFor(Locale.Default);
  }

  return {
    ...getMessagesFor(Locale.Default),
    ...getMessagesFor(getLocale()),
  };
};

export const saveLocale = (newLocale: string) => {
  const locale = getLocale();
  const newPathname = getPathname().replace(`/${locale}`, `/${newLocale}`);

  Cookies.set('language', newLocale, SHARED_COOKIE_OPTIONS);
  window.location.href = newPathname;
};

// implements natural number sorting eg. [G1, G10, G2] => [G1, G2, G10]
export const compareLocale = (a: string, b: string) =>
  a.localeCompare(b, getLocale(), { numeric: true });

export const getCountryLocale = (org: Organization) => {
  switch (org.country_code.toUpperCase()) {
    case CountryCode.Philippines:
      return Locale.Filipino;
    case CountryCode.Indonesia:
      return Locale.Indonesian;
    default:
      return Locale.Default;
  }
};
