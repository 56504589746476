import { isValid, parseISO, format, startOfDay, endOfDay } from 'date-fns';
import { isEmpty, omitBy } from 'lodash-es';
import { stringifyUrl } from 'query-string';

import { CourseTag } from 'models/courseTag';
import { Subject } from 'models/subject';

export enum Direction {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum PublishStatus {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

export const stringifyDate = (date?: Date) =>
  date ? format(date, 'yyyy-MM-dd') : undefined;

export const parseDate = (dateString = '') => {
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate) ? parsedDate : undefined;
};

export const hasActiveFilters = (
  filters: FetchCoursesQuery,
  skip: Array<keyof FetchCoursesQuery> = [],
) => {
  return !isEmpty(
    omitBy(
      filters,
      (value, key) => (skip as string[]).includes(key) || isEmpty(value),
    ),
  );
};

export type PaginationProps = Partial<{
  q: string;
  page: string | number;
  per: string | number;
}>;

export type FetchCoursesQuery = PaginationProps &
  Partial<{
    grades: string | string[];
    course_set_id: string;
    user_ids: string[];
    course_subset_id: string;
    sort_by: string;
    status: string;
    publish_status: PublishStatus;
    published_from: string;
    published_to: string;
    created_from: string;
    created_to: string;
    customGradeIds: string[];
    subject_ids: string[];
    otherTagIds: string[];
    tag_search: string;
  }>;

export const DEFAULT_SORT = `name:${Direction.ASCENDING}`;

export const buildDateQueries = (
  queryName: string,
  from?: string,
  to?: string,
) => {
  const fromDate = parseDate(from);
  // If no to date is present then use from date
  const toDate = parseDate(to ?? from);
  return {
    [`${queryName}_from`]: fromDate && String(startOfDay(fromDate).getTime()),
    [`${queryName}_to`]: toDate && String(endOfDay(toDate).getTime()),
  };
};

const getCourseTagIds = (
  customGradeIds: string[] = [],
  otherTagIds: string[] = [],
) => {
  const ids = [customGradeIds, otherTagIds].flat();
  return ids.length > 0 ? ids : undefined;
};

export const buildUrlWithCourseFiltersQuery = (
  url: string,
  query?: FetchCoursesQuery,
  extraQueries?: { [key: string]: string | undefined },
  options?: Record<string, never>,
) => {
  const courseTagIds = getCourseTagIds(
    query?.customGradeIds,
    query?.otherTagIds,
  );

  return stringifyUrl(
    {
      url,
      query: {
        q: query?.q,
        tag_search: query?.tag_search,
        course_set_id: query?.course_set_id,
        course_subset_id: query?.course_subset_id,
        level: query?.grades,
        page: `${query?.page ?? 1}`,
        per: `${query?.per ?? 15}`,
        sort_by: `${query?.sort_by ?? DEFAULT_SORT}`,
        publish_status: query?.publish_status,
        ...buildDateQueries(
          'published',
          query?.published_from,
          query?.published_to,
        ),
        ...buildDateQueries('created', query?.created_from, query?.created_to),
        user_ids: query?.user_ids,
        course_tag_ids: courseTagIds,
        subject_ids: query?.subject_ids,
        ...extraQueries,
      },
    },
    { skipEmptyString: true, arrayFormat: 'bracket', ...options },
  );
};

export type FetchTagsQuery = Partial<CourseTag & Subject> & PaginationProps;

export const buildUrlWithTagFiltersQuery = (
  url: string,
  query?: FetchTagsQuery,
  options?: Record<string, never>,
) => {
  return stringifyUrl(
    {
      url,
      query: {
        q: query?.name,
        page: `${query?.page ?? 1}`,
        type: query?.type,
      },
    },
    { skipEmptyString: true, ...options },
  );
};
