import { schema } from 'normalizr';

export interface Subject {
  id: string;
  name: string;
  organization_id?: string | null;
  tagged_count?: number;
}

export const subjectSchema = new schema.Entity('subjects');
