import { createSelector } from 'reselect';
import { get, sortBy, camelCase } from 'lodash-es';
import { denormalize } from 'normalizr';

import {
  getSubjectEntitySlice,
  getCourseTagEntitySlice,
  getOrganizationsEntitySlice,
  getOrganizationsSlice,
  getUserSlice,
  getUsersEntitySlice,
} from 'selectors/entities';
import { RoleTypes } from 'components/pages/teams/RolesDropdown';
import {
  Organization,
  organizationSchema,
  Permission,
} from 'models/organization';
import { User } from 'models/user';

export const getSelectedOrganizationId = createSelector(
  getOrganizationsSlice,
  slice => slice.selectedId,
);

export const getIsFetchingOrg = createSelector(
  getOrganizationsSlice,
  slice => slice.isFetchingOrg,
);

export const getOrganizations = createSelector(
  getCourseTagEntitySlice,
  getSubjectEntitySlice,
  getUsersEntitySlice,
  getOrganizationsEntitySlice,
  (
    { byId: courseTags },
    { byId: subjects },
    { byId: users },
    { byId: organizations, ids },
  ) => {
    const normalizedOrganizations = ids.map(id => organizations[id]);
    return normalizedOrganizations.map(o =>
      denormalize(o, organizationSchema, { courseTags, subjects, users }),
    );
  },
);

export const getHasValidLicense = createSelector(
  getOrganizations,
  getOrganizationsSlice,
  (organizations, { selectedId: selectedOrganizationId }) => {
    return !!get(
      organizations.find(o => o.id === selectedOrganizationId),
      'can_import_license_questions',
    );
  },
);

export const getAdministrableOrganizations = createSelector(
  getUserSlice,
  ({ user }) => user?.administrable_organizations || [],
);

export const getOrganization = (id: string) =>
  createSelector(getOrganizations, organizations => {
    return organizations.find(o => o.id === id);
  });

export const getSelectedOrganization = createSelector(
  [getSelectedOrganizationId, getOrganizations],
  (selectedId, organizations) =>
    organizations.find(o => o.id === selectedId) as Organization,
);

export const getMembersList = createSelector(
  getSelectedOrganization,
  (organization: Organization) => {
    const membersList = sortBy(organization.members, [m => m.name]) || [];

    return membersList.map(member => {
      const { id } = member;
      const team = organization.teams.find(t => {
        const isUserInTeam = !!t.users.find(u => u.id == id);
        return isUserInTeam;
      });
      let roleName: RoleTypes = 'noRole';

      if (!organization?.use_teams_in_qcreate) {
        return { ...member, roleName };
      } else {
        if (team) {
          roleName = camelCase(team.name) as RoleTypes;

          return {
            ...member,
            roleName,
          };
        }
        return { ...member, roleName };
      }
    });
  },
);

export const getCurrentOrgMembers = createSelector(
  getSelectedOrganization,
  selectedOrg => {
    if (!selectedOrg) {
      return [];
    }

    return (selectedOrg.members || []) as User[];
  },
);

export const getOrgCountrySubjects = createSelector(
  getSelectedOrganization,
  organization => {
    const subjects = organization?.country_subjects || [];
    return subjects.slice();
  },
);

export const getIsUserRestricted = createSelector(
  getSelectedOrganization,
  organization =>
    organization?.restricted ||
    (organization?.use_teams_in_qcreate &&
      organization?.permissions?.length === 0),
);

export const getIsUserOrgAdmin = createSelector(
  getSelectedOrganization,
  organization => organization?.admin || false,
);

export const isLimitedEditor = createSelector(
  getSelectedOrganization,
  organization => organization?.role === 'Limited Editors',
);

export const hasPermission = (permission: Permission) =>
  createSelector(
    getSelectedOrganization,
    currentOrganization =>
      !!currentOrganization?.permissions?.includes(permission),
  );

export const getIsMeikoOrganization = createSelector(
  getSelectedOrganization,
  organization => organization?.alliance || false,
);

export const isPointSystemEnabled = createSelector(
  getSelectedOrganization,
  organization => organization?.enable_point_system,
);

export const isPointPerAnswerEnabled = createSelector(
  getSelectedOrganization,
  organization => organization?.enable_point_per_answer,
);

export const isPermittedToUseImportQuestions = createSelector(
  [getSelectedOrganization, isLimitedEditor, getIsUserOrgAdmin],
  (organization, isLimitedEditorRole, isAdmin) => {
    if (isAdmin) {
      return true;
    }

    if (isLimitedEditorRole) {
      return false;
    }

    if (!organization?.role && organization?.use_teams_in_qcreate) {
      return false;
    }

    return true;
  },
);
