import React, { useRef } from 'react';
import c from 'classnames';
import { useIntl } from 'react-intl';

import Tooltip from 'components/atoms/tooltip/Tooltip';

const Tag = ({
  children,
  className,
  tooltipText,
  isHighlighted = false,
  ...props
}: React.HTMLAttributes<HTMLSpanElement> & {
  tooltipText?: string;
  isHighlighted?: boolean;
}) => {
  const tag = (
    <span
      className={c(
        'flex items-center rounded px-12 h-32 text-12 mb-12 cursor-default',
        className,
        {
          'bg-grey-minus2': !isHighlighted,
          'bg-primary-plus1 text-white': isHighlighted,
        },
      )}
      data-testid="tag"
      {...props}
    >
      {children}
    </span>
  );

  if (tooltipText) return <Tooltip text={tooltipText}>{tag}</Tooltip>;

  return tag;
};

export const SidebarTagsContainer = React.memo(
  ({
    className,
    tags = [],
  }: { tags?: string[] } & React.HTMLAttributes<HTMLDivElement>) => {
    const { formatMessage: f } = useIntl();
    const container = useRef<HTMLDivElement>(null);
    const [shownTags, setShownTags] = React.useState<string[]>(tags);
    const [hiddenTags, setHiddenTags] = React.useState<string[]>();

    React.useEffect(() => {
      if (tags) {
        setShownTags(tags);

        let width = 50;
        // 440 = width of sidebar course name container * 2
        // since the tags can be displayed in two lines
        const containerWidth = 440;

        tags.every((t, i) => {
          // 24 = padding on both sides of the tag
          // 7.5 = size of each character
          width = width + (24 + t.length * 7.5);

          if (containerWidth && width > containerWidth) {
            const lastShownTagIndex = i - 1;
            setShownTags(tags.slice(0, lastShownTagIndex));
            setHiddenTags(tags.slice(lastShownTagIndex));
          }

          return width < containerWidth;
        });
      }
    }, [tags, setShownTags, setHiddenTags]);

    if (tags.length < 1) return null;

    return (
      <div
        ref={container}
        className={c(
          'flex flex-wrap mt-16 -mb-12 w-full font-normal',
          className,
        )}
        data-testid="sidebar-tags-container"
      >
        {shownTags?.map((t, i) => (
          <Tag className="mr-8 flex-shrink-0" key={`tag-${i}`}>
            {t}
          </Tag>
        ))}

        {hiddenTags && (
          <Tag tooltipText={hiddenTags.join(f({ id: 'common.comma' }))}>
            +{hiddenTags.length}
          </Tag>
        )}
      </div>
    );
  },
);

export const TagsContainer = ({
  className,
  tags = [],
  highlightedTag,
}: { tags?: string[]; highlightedTag?: string } & React.HTMLAttributes<
  HTMLDivElement
>) => {
  const { formatMessage: f } = useIntl();
  const container = useRef<HTMLDivElement>(null);
  const [shownTags, setShownTags] = React.useState<string[]>(tags);
  const [hiddenTags, setHiddenTags] = React.useState<string[]>();

  React.useLayoutEffect(() => {
    const handleOverflow = () => {
      if (container.current) {
        let width = 100;
        const containerWidth = container.current?.clientWidth;

        Array.from(container.current.childNodes).every((t, i) => {
          width = width + (t as HTMLSpanElement).offsetWidth;

          if (containerWidth && width > containerWidth) {
            const lastShownTagIndex = i - 1;
            setShownTags(tags.slice(0, lastShownTagIndex));
            setHiddenTags(tags.slice(lastShownTagIndex));
          }

          return width < containerWidth;
        });
      }
    };

    handleOverflow();
  }, [container, tags, setShownTags, setHiddenTags]);

  if (tags.length < 1) return null;

  const isTagHighlighted = (tagNames: string[]) =>
    tagNames.some(tn => tn.toLowerCase() === highlightedTag?.toLowerCase());

  return (
    <div
      ref={container}
      className={c('flex flex-wrap mt-16 -mb-12 w-full', className)}
      data-testid="tags-container"
    >
      {shownTags?.map((t, i) => (
        <Tag
          className={'mr-8 flex-shrink-0'}
          isHighlighted={isTagHighlighted([t])}
          key={i}
        >
          {t}
        </Tag>
      ))}

      {hiddenTags && (
        <Tag
          isHighlighted={isTagHighlighted(hiddenTags)}
          tooltipText={hiddenTags.join(f({ id: 'common.comma' }))}
        >
          +{hiddenTags.length}
        </Tag>
      )}
    </div>
  );
};

export default Tag;
