import c from 'classnames';
import React, { useEffect } from 'react';
import { useLocation, useMatch, Link } from '@reach/router';
import { useIntl } from 'react-intl';

import { getLocale } from 'utils/i18n';
import Sidebar from 'components/organisms/Sidebar/Sidebar';
import {
  Bundle,
  Topic,
  useCourseTreeState,
  useCourseTreeDispatch,
} from 'context/CourseTreeContext';
import MultilineTruncate from 'components/atoms/MultilineTruncate';
import { ReactComponent as ToggleIcon } from 'assets/icons/circle-forward.svg';
import { ReactComponent as ToggleAllIcon } from 'assets/icons/open-all.svg';
import Tooltip from 'components/atoms/tooltip/Tooltip';
import InaccessibleContentIndicator from 'components/atoms/InaccessibleContentIndicator';
import { SidebarTagsContainer } from 'components/atoms/Tag';
import { getSortedTagNames } from 'utils/course';
import AssessmentCoursePill from 'components/atoms/AssessmentCoursePill';

import './CourseTreeSidebar.css';

const ChildIndicator = ({
  id,
  isOpen = false,
  isDisabled = false,
  isToggleable = true,
  onClick,
  className,
}: {
  id?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  isOpen?: boolean;
  isDisabled?: boolean;
  isToggleable?: boolean;
  className?: string;
}) => {
  const commonClasses = c(className, 'child-indicator text-grey rounded-full');
  const bgClass = isDisabled ? 'bg-grey-minus2' : 'bg-grey';

  return isToggleable ? (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={c(
        commonClasses,
        'focus:outline-none',
        isOpen && 'rotate-90',
        isDisabled && 'bg-grey-minus2 cursor-default',
      )}
      data-testid={`sidebar-item-toggle-${id}`}
    >
      <ToggleIcon
        height="16"
        className={c('fill-current', isDisabled && 'invisible')}
      />
    </button>
  ) : (
    <span
      className={c(commonClasses, 'child-indicator--small h-10 w-10', bgClass)}
    />
  );
};

interface ItemProps {
  id: string;
  path: string;
  disabled?: boolean;
  isActive: boolean;
  children: React.ReactNode;
  className?: string;
}

const Item = ({
  path,
  disabled = false,
  isActive,
  children,
  className,
}: ItemProps) => {
  const contents = (
    <>
      <span
        className={c(
          'min-h-56 h-full border-l-6 border-primary mr-16',
          !isActive && 'invisible',
        )}
      />
      {children}
    </>
  );
  const classes = c(
    'text-12 relative h-56 flex items-center pr-16',
    !disabled && isActive && 'bg-primary-minus2 font-bold select-none',
    !disabled && !isActive && 'hover:bg-grey-minus2',
    className,
  );

  return isActive || disabled ? (
    <div className={classes}>{contents}</div>
  ) : (
    <Link to={path} className={classes}>
      {contents}
    </Link>
  );
};

const ItemContent = ({
  isRestricted,
  name,
  id,
}: {
  isRestricted: boolean;
  name?: string;
  id?: string;
}) => {
  if (isRestricted) {
    return (
      <>
        <MultilineTruncate className={c('text-grey')}>{name}</MultilineTruncate>
        <div className="ml-auto" data-testid={`restricted-${id}`}>
          <InaccessibleContentIndicator className="h-16 text-grey" />
        </div>
      </>
    );
  }

  return <MultilineTruncate tooltipText={name}>{name}</MultilineTruncate>;
};

const TopicItem = ({
  isEmpty = false,
  children,
  className,
  topic,
  isSchoolAssessmentCourse,
  ...props
}: {
  isEmpty?: boolean;
  topic?: Topic;
  isSchoolAssessmentCourse?: boolean;
} & Omit<ItemProps, 'isActive' | 'path'>) => {
  const topicChaptersPath = `/${getLocale()}/topics/${props.id}`;
  const topicQuestionsPath = `${topicChaptersPath}/questions`;
  const isTopicChaptersPath = useMatch(topicChaptersPath);
  const isTopicQuestionsPath = useMatch(topicQuestionsPath);
  const isRestricted = !topic?.accessible;
  const topicPath = isSchoolAssessmentCourse
    ? topicQuestionsPath
    : topicChaptersPath;
  return (
    <Item
      {...props}
      path={topicPath}
      disabled={isEmpty || isRestricted}
      isActive={!!isTopicChaptersPath || !!isTopicQuestionsPath}
      className={c('sidebar-item--topic', className)}
    >
      <ChildIndicator
        isToggleable={false}
        isDisabled={isEmpty || isRestricted}
        className="mr-16 z-10"
      />
      {children}
    </Item>
  );
};

const BundleItem = ({
  bundle,
  isLast,
  isSchoolAssessmentCourse,
}: {
  bundle: Bundle;
  isLast: boolean;
  isSchoolAssessmentCourse: boolean;
}) => {
  const { formatMessage: f } = useIntl();

  const bundleId = bundle.id;
  const { isOpened } = useCourseTreeState();
  const dispatch = useCourseTreeDispatch();
  const isRestricted = !bundle.accessible;

  const path = `/${getLocale()}/bundles/${bundle.id}`;
  const isActive = useMatch(path);
  const location = useLocation();
  const includesActiveTopic = bundle.topics?.some((t: Topic) => {
    const topicChaptersPath = `/${getLocale()}/topics/${t.id}`;
    const topicQuestionsPath = `${topicChaptersPath}/questions`;
    return [topicChaptersPath, topicQuestionsPath].includes(location.pathname);
  });

  const shouldBeOpen = !!isActive || !!includesActiveTopic;

  useEffect(() => {
    if (shouldBeOpen) {
      dispatch({ type: 'open', id: bundleId });
    }
  }, [shouldBeOpen, dispatch, bundleId]);

  const isOpen = isOpened(bundle.id);
  const isEmpty = bundle.topics?.length === 0;

  return (
    <>
      <Item
        id={bundle.id}
        isActive={!!isActive}
        path={path}
        key={bundle.id}
        disabled={isRestricted}
        className={c(
          'sidebar-item--bundle border-b border-t border-grey-minus2 flex',
          isLast && !isOpen && 'last',
        )}
      >
        <ChildIndicator
          id={bundle.id}
          isOpen={isOpen}
          isDisabled={isRestricted}
          onClick={e => {
            e.preventDefault();
            dispatch({ type: isOpen ? 'close' : 'open', id: bundle.id });
          }}
          className="mr-12 z-10"
        />
        <ItemContent
          isRestricted={isRestricted}
          name={bundle.name}
          id={bundle.id}
        />
      </Item>

      {isOpen &&
        !isEmpty &&
        bundle.topics?.map((t: Topic, index: number) => {
          return (
            <TopicItem
              id={t.id}
              key={t.id}
              className={c(bundle.topics.length === index + 1 && 'last')}
              topic={t}
              isSchoolAssessmentCourse={isSchoolAssessmentCourse}
            >
              <ItemContent
                isRestricted={!t.accessible}
                name={t.name}
                id={t.id}
              />
            </TopicItem>
          );
        })}

      {isOpen && isEmpty && (
        <TopicItem id="" isEmpty key={`${bundle.id}-empty`} className="last">
          <span className="text-grey italic">
            {f({ id: 'sidebar.noTopics' })}
          </span>
        </TopicItem>
      )}
    </>
  );
};

const CourseTreeSidebar = () => {
  const { formatMessage: f } = useIntl();

  const { isOpened, courseData: course, courseId } = useCourseTreeState();
  const dispatch = useCourseTreeDispatch();

  const openableBundleIds =
    course?.bundles
      ?.filter((b: Bundle) => !!b.topics)
      .map((b: Bundle) => b.id) || [];
  const allBundlesOpen = !openableBundleIds.some((id: string) => !isOpened(id));

  const path = `/${getLocale()}/courses/${courseId}`;
  const isActive = useMatch(path);
  const isEmpty = course ? course.bundles?.length < 1 : true;

  // Display nothing if id of course in context is not equal to courseId in URL
  // so we don't show data of previously displayed course
  const isFetching = !!course?.id && course?.id !== courseId;

  return (
    <Sidebar headerLinkUrl="/" headerLinkText={f({ id: 'sidebar.courseList' })}>
      {isOpen => {
        if (isOpen && course && !isFetching) {
          return (
            <>
              <Item
                id={course.id}
                isActive={!!isActive}
                path={path}
                key={courseId}
                className="flex-shrink-0 font-bold mt-8 h-auto"
              >
                <div className="flex flex-col flex-grow py-10">
                  <MultilineTruncate tooltipText={course.name}>
                    {course.name}
                  </MultilineTruncate>
                  {course.school_assessment_course && <AssessmentCoursePill />}
                  <SidebarTagsContainer tags={getSortedTagNames(course)} />
                </div>
                {!isEmpty && (
                  <Tooltip
                    text={f({
                      id: `sidebar.${
                        allBundlesOpen ? 'close' : 'open'
                      }AllBundles`,
                    })}
                    placement="bottom"
                  >
                    <button
                      onClick={e => {
                        e.preventDefault();
                        dispatch({
                          type: allBundlesOpen ? 'closeAll' : 'openAll',
                          ids: openableBundleIds,
                        });
                      }}
                      className={c('pr-16 -mr-16 focus:outline-none')}
                      data-testid={`sidebar-${
                        allBundlesOpen ? 'close' : 'open'
                      }-all`}
                    >
                      <ToggleAllIcon
                        height="20"
                        className={c(
                          'sidebar-toggle-all text-grey fill-current',
                          allBundlesOpen && 'rotate-180',
                        )}
                      />
                    </button>
                  </Tooltip>
                )}
              </Item>

              <div className="overflow-y-auto">
                {course?.bundles?.map((b: Bundle, index: number) => {
                  return (
                    <BundleItem
                      bundle={b}
                      isLast={course.bundles.length === index + 1}
                      key={b.id}
                      isSchoolAssessmentCourse={
                        !!course.school_assessment_course
                      }
                    />
                  );
                })}
              </div>

              {isEmpty && (
                <Item
                  id=""
                  path=""
                  disabled={true}
                  isActive={false}
                  className="last sidebar-item--bundle border-b border-t border-grey-minus2"
                >
                  <ChildIndicator
                    isToggleable={true}
                    isDisabled={true}
                    className="mr-12 z-10"
                  />
                  <span className="text-grey italic">
                    {f({ id: 'sidebar.noBundles' })}
                  </span>
                </Item>
              )}
            </>
          );
        }

        return null;
      }}
    </Sidebar>
  );
};

export default CourseTreeSidebar;
