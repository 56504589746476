import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { init as initializeSentry } from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { Replay } from '@sentry/replay';

// Polyfills for Safari
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/id';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/id';

// Polyfill for IE
import 'core-js/stable/array';
import 'core-js/stable/object';
import 'core-js/stable/string/ends-with';
import 'core-js/stable/string/starts-with';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.symbol';
import 'core-js/stable/symbol/iterator';
import '@formatjs/intl-getcanonicallocales/polyfill';

import App from 'app/App';
import store from 'app/store';
import { initialize } from 'utils/app';
import { getLocale, getMessages } from 'utils/i18n';
import config from 'appConfig';

initializeSentry({
  dsn: config.REACT_APP_SENTRY_DSN,
  environment: config.REACT_APP_SITE_ENV,
  release: config.REACT_APP_VERSION,
  integrations: [
    new BrowserTracing(),
    new Replay({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],

  // This sets the sample rate to be 0% (we only wanted to record sessions with errors). You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

initialize();

export const root = (
  <Provider store={store}>
    <IntlProvider
      locale={getLocale()}
      key={getLocale()}
      messages={getMessages()}
    >
      <App />
    </IntlProvider>
  </Provider>
);

ReactDOM.render(root, document.getElementById('root'));
