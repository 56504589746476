import React, { ButtonHTMLAttributes } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from '@reach/router';

import {
  fetchOrganizations as fetchOrganizationsAction,
  selectOrganization,
} from 'slices/organizations';
import { fetchOrganization } from 'slices/organization';
import {
  getOrganizations,
  getSelectedOrganization,
} from 'selectors/organizations';
import MenuItem from 'components/atoms/Dropdown/MenuItem';
import Dropdown from 'components/atoms/Dropdown/Dropdown';
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg';
import { compareLocale, getLocale } from 'utils/i18n';
import { getUser } from 'selectors/user';
import { useDeferredAsync } from 'hooks';
import Spinner from 'components/atoms/Spinner';

const defaultClasses = `
text-20 font-bold px-16 py-8
truncate max-w-600 ml-48
`;

const DropdownButton = ({
  children,
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    type="button"
    className={classNames(
      defaultClasses,
      'flex items-center rounded focus:outline-none focus:bg-grey-minus2 hover:bg-grey-minus2',
      className,
    )}
    {...props}
  >
    <span className="truncate max-w-600">{children}</span>
    <DropdownIcon className="ml-8 fill-current text-grey-plus2" height="16" />
  </button>
);

const OrganizationSwitcher = ({ className }: { className?: string }) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useSelector(getUser);
  const organizations = useSelector(getOrganizations);
  const selectedOrganization = useSelector(getSelectedOrganization);
  const [orgName, setOrgName] = React.useState(
    selectedOrganization?.name || '',
  );

  const { done, run: fetchOrganizations } = useDeferredAsync(
    fetchOrganizationsAction,
  );

  if (!user) return null;

  const handleSelect = async (organizationId: string) => {
    const orgSelected = organizations.find(org => org.id === organizationId);

    if (!orgSelected || organizationId === selectedOrganization.id) {
      return;
    }

    setOrgName(orgSelected.name);

    if (orgSelected.permissions === undefined) {
      await dispatch(fetchOrganization(organizationId));
    }

    dispatch(selectOrganization(organizationId));

    if (pathname.includes(`/${getLocale()}/`)) {
      navigate(`/${getLocale()}`);
    }
  };

  const menuItems =
    organizations.length === 1
      ? [
          <MenuItem key="loading" isDisabled className="inline-flex text-grey">
            <Spinner className="h-16 mr-8 fill-current" />
            <span className="font-semibold">{f({ id: 'common.loading' })}</span>
          </MenuItem>,
        ]
      : organizations
          .map(({ id, name }) => ({ id, name }))
          .sort((a, b) => compareLocale(a.name, b.name))
          .map(o => (
            <MenuItem key={o.id} isSelected={o.id === selectedOrganization.id}>
              {o.name}
            </MenuItem>
          ));

  if (!user.has_multiple_organizations) {
    return (
      <span
        className={classNames(defaultClasses, className, 'cursor-default')}
        data-testid="organization-switcher"
      >
        {orgName}
      </span>
    );
  } else {
    return (
      <Dropdown
        onSelect={handleSelect}
        menuItems={menuItems}
        className="max-w-400 max-h-50p-screen overflow-y-scroll"
        onMenuClick={() => {
          if (!done && organizations.length === 1) {
            fetchOrganizations();
          }
        }}
      >
        <DropdownButton
          className={className}
          data-testid="org-switcher-dropdown"
        >
          {orgName}
        </DropdownButton>
      </Dropdown>
    );
  }
};

export default OrganizationSwitcher;
