import { cloneDeep } from 'lodash';

import { Course } from 'models/course';
import { Subject } from 'models/subject';
import { Course as CourseTree } from 'context/CourseTreeContext';
import { compareLocale } from 'utils/i18n';
import { sort, reverseSort } from 'utils/sort';

export const getSubjects = (course: Course | CourseTree) => {
  const subjects = course.subjects || [];
  const countrySubjects = subjects.filter((s: Subject) => !s.organization_id);
  const customSubjects = subjects.filter((s: Subject) => s.organization_id);
  return { countrySubjects, customSubjects };
};

const getSystemGrades = (course?: Course | CourseTree) => {
  return course?.meta?.level || course?.level || course?.grade_levels || [];
};

export const getGrades = (course?: Course | CourseTree) => {
  const systemGrades = getSystemGrades(course);
  const customGrades =
    course?.course_tags
      ?.filter(tag => tag.type === 'grade')
      .sort((a, b) => compareLocale(a.name, b.name)) || [];
  return { systemGrades, customGrades };
};

export const getOtherTags = (course?: Course | CourseTree) => {
  if (!course?.course_tags) {
    return [];
  }

  return course.course_tags.filter(tag => tag.type === 'other');
};

export const getSortedTagNames = (course?: Course | CourseTree) => {
  if (!course) return [];

  // prevent redux state mutation when sorting tags
  const clonedCourse = cloneDeep(course);

  const { systemGrades, customGrades } = getGrades(clonedCourse);
  const { countrySubjects, customSubjects } = getSubjects(clonedCourse);

  return [
    reverseSort(systemGrades),
    sort(customGrades.map(g => g.name)),
    sort(countrySubjects.map(s => s.name)),
    sort(customSubjects.map(s => s.name)),
    sort(getOtherTags(course).map(t => t.name)),
  ].flat();
};
