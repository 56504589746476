import React from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import './AppLoadingPage.css';

const AppLoadingPage = () => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="min-h-screen bg-grey-plus2 flex">
      <div className="flex flex-col items-center m-auto text-white">
        <Logo height="24" className="mb-8 fill-current" />
        <span className="LoadingText font-bold text-12">
          {f({ id: 'common.loading' })}
        </span>
      </div>
    </div>
  );
};

export default AppLoadingPage;
