import React from 'react';
import { useIntl, MessageDescriptor } from 'react-intl';
import { v4 } from 'uuid';

const useFormatParagraph = () => {
  const { formatMessage } = useIntl();

  return (
    messageDescriptor: MessageDescriptor,
    values?: { [key: string]: string },
  ) =>
    formatMessage(messageDescriptor, {
      ...values,
      p: s => <p key={v4()}>{s}</p>,
    });
};

export default useFormatParagraph;
