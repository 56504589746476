import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

const PageTitle = ({ id }: { id: string }) => {
  const { formatMessage: f } = useIntl();
  const pageTitle = f({ id });

  return (
    <Helmet>
      <title>{f({ id: 'common.pageTitle' }, { pageTitle })}</title>
    </Helmet>
  );
};

export default PageTitle;
