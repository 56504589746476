import React, { Suspense } from 'react';
import { Match, Redirect, RouteComponentProps, Router } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import { parse } from 'query-string';

import { useAuth } from 'context/AuthContext';
import { Permission } from 'models/organization';
import {
  isPermittedToUseImportQuestions,
  getIsUserRestricted,
  hasPermission,
  getIsUserOrgAdmin,
  getHasValidLicense,
  getIsMeikoOrganization,
  getOrganization,
  getSelectedOrganization,
} from 'selectors/organizations';
import { Locales, getLocale } from 'utils/i18n';
import {
  NestedRoute,
  ProtectedRoute,
  ResetScrollOnNavigate,
} from 'utils/router';
import config from 'appConfig';
import { selectOrganization } from 'slices/organizations';
import AppFallback from 'components/pages/AppFallback';
import Login from 'components/pages/login/Login';
import PageWithSidebar from 'components/templates/PageWithSidebar';
import { NotFound } from 'components/pages/NotFound';
import { clearCookies, setSelectedOrganizationIdCookies } from 'utils/cookies';

const CourseList = React.lazy(() =>
  import(
    /* webpackChunkName: "courses" */ 'components/pages/courseList/CourseList'
  ),
);

const Teams = React.lazy(() =>
  import(/* webpackChunkName: "teams" */ 'components/pages/teams/Teams'),
);

const Tags = React.lazy(() =>
  import(/* webpackChunkName: "tags" */ 'components/pages/tags/TagsGrades'),
);

const TagsSubjects = React.lazy(() =>
  import(/* webpackChunkName: "tags" */ 'components/pages/tags/TagsSubjects'),
);

const TagsOthers = React.lazy(() =>
  import(/* webpackChunkName: "tags" */ 'components/pages/tags/TagsOthers'),
);

const CoursePage = React.lazy(() =>
  import(/* webpackChunkName: "course" */ 'components/pages/course/CoursePage'),
);

const CreateCoursePage = React.lazy(() =>
  import(
    /* webpackChunkName: "new-course" */ 'components/pages/course/CreateCoursePage'
  ),
);

const CreateAssessmentCoursePage = React.lazy(() =>
  import(
    /* webpackChunkName: "new-assessment-course" */ 'components/pages/course/CreateAssessmentCoursePage'
  ),
);

const EditCoursePage = React.lazy(() =>
  import(
    /* webpackChunkName: "edit-course" */ 'components/pages/course/EditCoursePage'
  ),
);

const UnpublishPage = React.lazy(() =>
  import(
    /* webpackChunkName: "unpublish" */ 'components/pages/course/publish/UnpublishPage'
  ),
);

const PublishPage = React.lazy(() =>
  import(
    /* webpackChunkName: "publish" */ 'components/pages/course/publish/PublishPage'
  ),
);

const Bundle = React.lazy(() =>
  import(/* webpackChunkName: "bundle" */ 'components/pages/bundle/BundlePage'),
);

const CreateBundlePage = React.lazy(() =>
  import(
    /* webpackChunkName: "new-bundle" */ 'components/pages/bundle/CreateBundlePage'
  ),
);

const EditBundlePage = React.lazy(() =>
  import(
    /* webpackChunkName: "edit-bundle" */ 'components/pages/bundle/EditBundlePage'
  ),
);

const Topic = React.lazy(() =>
  import(/* webpackChunkName: "topic" */ 'components/pages/topic/Topic'),
);

const CreateTopicPage = React.lazy(() =>
  import(
    /* webpackChunkName: "new-topic" */ 'components/pages/topic/CreateTopicPage'
  ),
);

const EditTopicPage = React.lazy(() =>
  import(
    /* webpackChunkname: "edit-topic" */ 'components/pages/topic/EditTopicPage'
  ),
);

const TopicQuestionsPage = React.lazy(() =>
  import(/* topic-questions */ 'components/pages/topic/questions/Questions'),
);

const ImportQuestionsPage = React.lazy(() =>
  import(
    /* webpackChunkName:"import-questions" */ 'components/pages/importQuestions'
  ),
);

const Chapter = React.lazy(() =>
  import(/* webpackChunkName: "chapter" */ 'components/pages/chapter/Chapter'),
);

const QuestionPage = React.lazy(() =>
  import(
    /* webpackChunkName: "question" */ 'components/pages/question/QuestionPage'
  ),
);

const PassagePage = React.lazy(() =>
  import(
    /* webpackChunkName: "passage" */ 'components/pages/passage/PassagePage'
  ),
);

const UnverifiedPage = React.lazy(() =>
  import(
    /* webpackChunkName: "unverified" */ 'components/pages/UnverifiedPage'
  ),
);

const ContentCreationFailedPage = React.lazy(() =>
  import(
    /* webpackChunkName: "content-creation-failed" */ 'components/pages/ContentCreationFailedPage'
  ),
);

const Localized = NestedRoute;
const TopicQuestions = NestedRoute;

const LoginPage = ({ isTokenExpired }: { isTokenExpired: boolean }) => (
  <Login isTokenExpired={isTokenExpired} />
);

const OrganizationUrlRedirect = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  const dispatch = useDispatch();
  const org = useSelector(getOrganization(organizationId));

  // switch to the org if the user is a member. Otherwise, retain the currently
  // selected one
  if (org) {
    dispatch(selectOrganization(organizationId));
  }

  return <Redirect to={`/${getLocale()}`} noThrow />;
};

const RootPathRedirects = (_: RouteComponentProps) => (
  <Match path="/">
    {() => {
      const href = window.location.href;
      const { organization_id: orgId } = parse(window.location.search);

      if (orgId) {
        setSelectedOrganizationIdCookies(orgId as string);
        window.location.href = '/';
      }

      const getResourceId = (resourceUrlRegex: RegExp) => {
        const matchResult = href?.match(resourceUrlRegex);
        // return first capture group (resource ID)
        return matchResult?.[1];
      };

      // Redirect URLs of legacy Create to new ones

      const questionId = getResourceId(
        /\/#courses\/\w+\/bundles\/\w+\/topics\/\w+\/questions\/(\w+)/,
      );
      if (questionId)
        return <Redirect to={`/en/questions/${questionId}`} noThrow />;

      const topicId = getResourceId(
        /\/#courses\/\w+\/bundles\/\w+\/topics\/(\w+)/,
      );
      if (topicId) return <Redirect to={`/en/topics/${topicId}`} noThrow />;

      const bundleId = getResourceId(/\/#courses\/\w+\/bundles\/(\w+)/);
      if (bundleId) return <Redirect to={`/en/bundles/${bundleId}`} noThrow />;

      const courseId = getResourceId(/\/#courses\/(\w+)/);
      if (courseId) return <Redirect to={`/en/courses/${courseId}`} noThrow />;

      const organizationId =
        getResourceId(/\/#organizations\/(\w+)/) || (orgId as string);
      if (organizationId) {
        return <OrganizationUrlRedirect organizationId={organizationId} />;
      }

      // If path doesn't match any of legacy Create URLs then just redirect to /en
      return <Redirect from="/" to={getLocale()} noThrow />;
    }}
  </Match>
);

const AuthorizedRoutes = (_: RouteComponentProps) => {
  const isUserRestricted = useSelector(getIsUserRestricted);
  const isUserPublisher = useSelector(hasPermission(Permission.Publish));
  const isUserOrgAdmin = useSelector(getIsUserOrgAdmin);
  const canImportFromTestBank = useSelector(getHasValidLicense);
  const isVerifiedOrganization = useSelector(getSelectedOrganization)?.verified;
  const permittedToUseImportQuestions = useSelector(
    isPermittedToUseImportQuestions,
  );

  if (!isVerifiedOrganization)
    return <Redirect to={`/${getLocale()}/unverified`} noThrow />;

  return (
    <Router>
      <CourseList path="/" />
      <Teams path="/teams" />
      <ProtectedRoute path="/tags" canAccess={isUserOrgAdmin}>
        <Tags path="/" />
        <TagsSubjects path="/subject" />
        <TagsOthers path="/other" />
        <NotFound default />
      </ProtectedRoute>

      <PageWithSidebar path="/">
        <ProtectedRoute path="courses/:courseId" canAccess={!isUserRestricted}>
          <CoursePage path="/" />
          <EditCoursePage path="edit" />
          <CreateBundlePage path="bundles/new" />
          <ProtectedRoute path="publish" canAccess={isUserPublisher}>
            <PublishPage path="/" />
            <NotFound default />
          </ProtectedRoute>
          <ProtectedRoute path="unpublish" canAccess={isUserPublisher}>
            <UnpublishPage path="/" />
            <NotFound default />
          </ProtectedRoute>
          <NotFound default />
        </ProtectedRoute>

        <ProtectedRoute path="bundles/:bundleId" canAccess={!isUserRestricted}>
          <Bundle path="/" />
          <EditBundlePage path="edit" />
          <CreateTopicPage path="topics/new" />
          <NotFound default />
        </ProtectedRoute>

        <Topic path="topics/:topicId" />
        <ProtectedRoute path="topics/:topicId" canAccess={!isUserRestricted}>
          <EditTopicPage path="edit" />

          <TopicQuestions path="questions">
            <TopicQuestionsPage path="/" />
            <NotFound default />
          </TopicQuestions>

          <ProtectedRoute
            path="organizations/quipper/import"
            canAccess={permittedToUseImportQuestions && canImportFromTestBank}
          >
            <ImportQuestionsPage path="/" />
            <NotFound default />
          </ProtectedRoute>

          <ProtectedRoute
            path="organizations/:organizationId/import"
            canAccess={permittedToUseImportQuestions}
          >
            <ImportQuestionsPage path="/" />
            <NotFound default />
          </ProtectedRoute>

          <NotFound default />
        </ProtectedRoute>

        <NotFound default />
      </PageWithSidebar>

      <ProtectedRoute path="courses/new" canAccess={!isUserRestricted}>
        <CreateCoursePage path="/" />
      </ProtectedRoute>

      <ProtectedRoute
        path="assessment_courses/new"
        canAccess={!isUserRestricted}
      >
        <CreateAssessmentCoursePage path="/" />
      </ProtectedRoute>

      <ProtectedRoute path="chapters/failed" canAccess={!isUserRestricted}>
        <ContentCreationFailedPage path="/" contentType="chapter" />
        <NotFound default />
      </ProtectedRoute>

      <ProtectedRoute path="questions/failed" canAccess={!isUserRestricted}>
        <ContentCreationFailedPage path="/" contentType="question" />
        <NotFound default />
      </ProtectedRoute>

      <ProtectedRoute path="passages/failed" canAccess={!isUserRestricted}>
        <ContentCreationFailedPage path="/" contentType="passage" />
        <NotFound default />
      </ProtectedRoute>

      <ProtectedRoute path="chapters/:chapterId" canAccess={!isUserRestricted}>
        <Chapter path="/" />
        <NotFound default />
      </ProtectedRoute>

      <ProtectedRoute
        path="questions/:questionId"
        canAccess={!isUserRestricted}
      >
        <QuestionPage path="/" />
        <NotFound default />
      </ProtectedRoute>

      <ProtectedRoute path="passages/:passageId" canAccess={!isUserRestricted}>
        <PassagePage path="/" />
        <NotFound default />
      </ProtectedRoute>

      <NotFound default />
    </Router>
  );
};

const UnverifiedRoute = (_: RouteComponentProps) => {
  // This component redirects to the correct path when /unverified is directly accessed (e.g. via browser URL bar)
  // AuthorizedRoutes handles redirection to /unverified when the org is unverified

  const isVerifiedOrganization = useSelector(getSelectedOrganization)?.verified;

  if (isVerifiedOrganization)
    return <Redirect to={`/${getLocale()}`} noThrow />;

  return <UnverifiedPage />;
};

const Authenticated = () => {
  const isMeikoOrganization = useSelector(getIsMeikoOrganization);
  const dispatch = useDispatch();

  if (isMeikoOrganization) {
    // can't use from useAuth here since it redirects to /<locale>
    clearCookies();
    dispatch({ type: 'logout' });

    window.location.href = `${
      config.REACT_APP_MEIKO_QCREATE_URL
    }/?hl=${getLocale()}`;
    return null;
  }

  return (
    <div className="flex flex-col min-h-screen bg-grey-minus3">
      <div className="flex-grow">
        <Router>
          <RootPathRedirects path="/" />

          {Locales.map(locale => (
            <Localized key={locale} path={locale}>
              <AuthorizedRoutes default />
              <UnverifiedRoute path="/unverified" />
            </Localized>
          ))}
          <NotFound default />
        </Router>
        <ResetScrollOnNavigate />
      </div>
    </div>
  );
};

export const Routes: React.FC = () => {
  const { user, isTokenExpired } = useAuth();

  return (
    <Suspense fallback={<AppFallback />}>
      {user ? <Authenticated /> : <LoginPage isTokenExpired={isTokenExpired} />}
    </Suspense>
  );
};
