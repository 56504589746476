import { configureScope, Scope } from '@sentry/browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stringifyUrl } from 'query-string';
import { normalize } from 'normalizr';

import { ThunkActionCreator } from 'app/store';
import { get, put } from 'utils/api';
import { User } from 'models/user';
import { selectOrganization } from 'slices/organizations';
import { getSelectedOrganizationIdCookies } from 'utils/cookies';
import { organizationSchema } from 'models/organization';
import { addRecords } from 'slices/entities';

interface UserState {
  user: User | null;
  loading: boolean;
  error?: string;
}

export const initialState: UserState = {
  loading: false,
  user: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserStart(state) {
      state.loading = true;
      state.error = undefined;
    },
    fetchUserFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchUserDone(state, { payload: user }) {
      state.loading = false;

      configureScope((scope: Scope) => {
        scope.setUser({ id: user.id });
      });

      state.user = user;
    },
    updateUseAutoSave(state, { payload: useAutoSave }) {
      if (state.user) state.user.use_autosave = useAutoSave;
    },
  },
});

export const {
  fetchUserStart,
  fetchUserFail,
  fetchUserDone,
  updateUseAutoSave,
} = slice.actions;

export const fetchUser: ThunkActionCreator = () => async dispatch => {
  dispatch(fetchUserStart());

  const res = await get(
    stringifyUrl(
      {
        url: '/users/show_current',
        query: { organization_id: getSelectedOrganizationIdCookies() },
      },
      { skipEmptyString: true },
    ),
  );

  if (res.status >= 400) {
    dispatch(fetchUserFail('error'));
  } else {
    const { user, organization } = await res.json();

    dispatch(addRecords(normalize(organization, organizationSchema)));
    dispatch(selectOrganization(organization.id));
    dispatch(fetchUserDone(user));
  }
};

export const cancelDownloadCoursesJob: ThunkActionCreator<{
  id: string;
}> = ({ id }) => async () => {
  const res = await put(
    stringifyUrl({
      url: `/users/cancel_download_courses_job`,
      query: { job_id: id },
    }),
  );

  if (res.status >= 400) {
    throw res.status;
  }

  return await res.json();
};

export const updateUserAutoSaveSetting: ThunkActionCreator<{
  useAutoSave: boolean;
}> = ({ useAutoSave }) => async dispatch => {
  const res = await put(`/users/update_use_autosave`, {
    use_autosave: useAutoSave,
  });

  if (res.status >= 400) {
    throw res.status;
  } else {
    dispatch(updateUseAutoSave(useAutoSave));
  }
};

export default slice.reducer;
