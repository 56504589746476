import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

interface Props extends HTMLAttributes<HTMLSpanElement> {
  disabled?: boolean;
  type?: 'success' | 'secondary';
}

const typeClasses = {
  success: 'bg-success text-white',
  secondary: 'bg-grey-minus3 text-grey border border-grey',
};

const Pill = ({ type, children, className, disabled, ...props }: Props) => {
  const classes = disabled
    ? 'text-white bg-grey-minus1'
    : type && typeClasses[type];

  return (
    <span className={classNames('Pill', classes, className)} {...props}>
      {children}
    </span>
  );
};

export default Pill;
