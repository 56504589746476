import { schema } from 'normalizr';

import { lessonSchema, Lesson } from 'models/lesson';
import { Passage, passageSchema } from 'models/passage';
import { Question, questionSchema } from 'models/question';
import { Chapter } from 'models/chapter';

export interface Topic<T = Lesson, P = Passage, Q = Question> {
  course_id: string;
  course_name: string;
  school_assessment_course: boolean;
  bundle_id: string;
  bundle_name: string;
  id: string;
  lesson?: T;
  name: string;
  shuffle_answers: boolean;
  shuffle_questions: boolean;
  chapters_count: number;
  questions_count: number;
  published_at: string | null;
  passages: P[];
  questions: Q[];
  questions_label: string;
  chapters?: Chapter[];
  errors: string[];
  author: boolean;
  organization_id: string;
  number_of_questions: number;
  total_points: number;
  sum_of_questions_points: number;
  grades?: string | string[];
}

export const topicSchema = new schema.Entity(
  'topics',
  {
    lesson: lessonSchema,
    passages: [passageSchema],
    questions: [questionSchema],
  },
  {
    processStrategy: (value, parent, key) => {
      if (key === 'topics') {
        return {
          // Derive bundle_id because Topics retrieved from the fetchQuipperBundle
          // action don't have them and only have a reference to the bundle guid
          // https://github.com/quipper/monorepo-global/pull/912#discussion_r394091246
          bundle_id: parent.id,
          ...value,
        };
      }

      return { ...value };
    },
  },
);
