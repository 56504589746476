import { RootState } from 'app/rootReducer';

export const getCourseEntitySlice = (state: RootState) => {
  return state.entities.courses;
};

export const getSubjectEntitySlice = (state: RootState) =>
  state.entities.subjects;

export const getCourseTagEntitySlice = (state: RootState) =>
  state.entities.courseTags;

export const getOrganizationsEntitySlice = (state: RootState) =>
  state.entities.organizations;

export const getOrganizationsSlice = (state: RootState) => state.organizations;

export const getUserSlice = (state: RootState) => state.user;

export const getUsersEntitySlice = (state: RootState) => state.entities.users;
