import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { login as loginAction } from 'slices/login';
import { useDeferredAsync, useModal } from 'hooks';
import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import ForgotPasswordModal from 'components/pages/login/ForgotPasswordModal';
import StyledLogin from 'components/pages/login/StyledLogin';
import PasswordInput from 'components/atoms/PasswordInput';
import PageTitle from 'components/atoms/PageTitle';
import Alert from 'components/atoms/Alert';
import useFormatParagraph from 'hooks/useFormatParagraph';
import { SupportedBrowsersModal } from 'components/organisms/SupportedBrowsersModal';

interface Props {
  isTokenExpired?: boolean;
}

const Login = ({ isTokenExpired }: Props) => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const {
    open: openBrowsersModal,
    isOpen: isBrowsersModalOpen,
    close: closeBrowsersModal,
  } = useModal();

  const { formatMessage: f } = useIntl();
  const formatParagraph = useFormatParagraph();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { pending, status, run: login } = useDeferredAsync(loginAction);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({ username, password });
  };

  const errorMessageIdByStatus: { [key: number]: string } = {
    422: 'login.failed',
    403: 'login.not_a_teacher',
    500: 'login.server_error',
  };

  useEffect(() => {
    !(
      navigator.userAgent.indexOf('Chrome') > -1 ||
      navigator.userAgent.indexOf('Safari') > -1 ||
      navigator.userAgent.indexOf('Firefox') > -1
    ) && openBrowsersModal();
  }, [openBrowsersModal]);

  return (
    <div>
      <PageTitle id="login.pageTitle" />

      <ForgotPasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
      />

      <SupportedBrowsersModal
        isOpen={isBrowsersModalOpen}
        onClose={() => closeBrowsersModal()}
      />

      <StyledLogin>
        <form
          className="w-320 flex flex-col py-24"
          onSubmit={handleSubmit}
          data-testid="login-form"
        >
          <p className="text-16 mb-24">{f({ id: 'login.login' })}</p>

          <Input
            className="w-full mb-16"
            disabled={pending}
            type="text"
            value={username}
            onChange={e => setUsername(e.currentTarget.value)}
            placeholder={f({ id: 'login.usernamePlaceholder' })}
          />

          <PasswordInput
            disabled={pending}
            value={password}
            onChange={e => setPassword(e.currentTarget.value)}
            placeholder={f({ id: 'login.passwordPlaceholder' })}
          />

          {status && errorMessageIdByStatus[status] && (
            <Alert className="mt-16 mb-8 text-center">
              {formatParagraph({
                id: errorMessageIdByStatus[status],
              })}
            </Alert>
          )}

          {isTokenExpired && !status && (
            <Alert className="mt-16 mb-8 text-center">
              {f({ id: 'login.token_expired' })}
            </Alert>
          )}

          <button
            type="button"
            className="self-start mt-8 mb-24 text-primary-plus1 focus:outline-none"
            onClick={() => setIsPasswordModalOpen(true)}
          >
            {f({ id: 'forgotPassword.link' })}
          </button>

          <Button
            data-testid="login-button"
            className="w-full"
            disabled={pending}
            isLoading={pending}
            type="submit"
          >
            {f({ id: 'login.login' })}
          </Button>
        </form>
      </StyledLogin>
    </div>
  );
};

export default Login;
