import { compact } from 'lodash-es';

import { getLocale } from 'utils/i18n';
import { Section, SectionContentType } from 'models/section';

const videoSectionContentToMarkdown = (content: string) => {
  if (!content) {
    return;
  }

  // RegExp copied from legacy Create. Example: youtube:ABCD123
  const contentFormat = /^([a-z]+):([^#&?]+)$/;
  const match = content.match(contentFormat);

  if (match) {
    // index 0 contains the whole matched string
    const videoType = match[1];
    const videoId = match[2];
    return `![:${videoType}](${videoId})`;
  }

  return '';
};

const sectionToMarkdown = ({
  id,
  content,
  mime_type: mimeType,
  section_content_type: contentType,
}: Section) => {
  // Image and audio use reference-style syntax (see
  // https://daringfireball.net/projects/markdown/syntax#img)
  //
  // This method creates the markdown for the image or audio or just returns the
  // assumed markdown content
  switch (true) {
    case mimeType?.startsWith('audio'):
      return `![audio][${id}]`;
    case mimeType?.startsWith('image'):
      return `![image][${id}]`;
    case contentType === SectionContentType.Video:
      return videoSectionContentToMarkdown(content);
    default:
      return content;
  }
};

const sectionToMarkdownReference = ({
  id,
  mime_type: mimeType,
  media_url: mediaUrl,
}: Section) => {
  if (!mimeType) {
    return '';
  }

  // Image and audio use reference-style syntax (see
  // https://daringfireball.net/projects/markdown/syntax#img)
  //
  // This method creates the reference for the image or audio that is placed at
  // the bottom of the content
  switch (true) {
    case mimeType.startsWith('audio'):
      return `[audio:${id}]: ${mediaUrl}`;
    case mimeType.startsWith('image'):
      return `[${id}]: ${mediaUrl}`;
    default:
      return '';
  }
};

export const squashSections = (sections?: Section[]) => {
  if (!sections) {
    return;
  }

  // Input:
  //
  // sections = [
  //   { id: '1', mime_type: 'text/html', content: 'hello' },
  //   { id: '2', mime_type: 'audio/mp3', media_url: 'http://example.com/audio.mp3' },
  //   { id: '3', mime_type: 'image/png' }, media_url: 'http://example.com/image.png' },
  // ]
  //
  // Output:
  //
  // ```
  // hello
  // ![audio][2]
  // ![image][3]
  //
  // [audio:2]: http://example.com/audio.mp3
  // [3]: http://example.com/image.png
  // ```

  const contents = compact(sections.map(sectionToMarkdown)).join('\n\n');
  const references = compact(sections.map(sectionToMarkdownReference)).join(
    '\n',
  );
  const newLine = contents && references ? '\n\n' : '';

  // only add 2 newline separator if both contents and references have value
  return `${contents}${newLine}${references}`;
};

export const squashSectionsHtml = (sections: Section[]) => {
  return compact(sections.map(section => section.content_html)).join('\n\n');
};

export const formatIntoMath = (latex: string) => {
  return `[% ${latex} %]`;
};

export const getContentPath = ({
  contentType,
  nextId,
  topicId,
}: {
  contentType: 'chapters' | 'questions' | 'passages';
  nextId?: string;
  topicId?: string;
}) => ({
  nextPath: `/${getLocale()}/${contentType}/${nextId}`,
  topicOverviewPath: `/${getLocale()}/topics/${topicId}${
    contentType === 'chapters' ? '' : '/questions'
  }`,
});
