import { schema } from 'normalizr';
import { merge } from 'lodash-es';

import { bundleSchema, Bundle } from 'models/bundle';
import { Subject, subjectSchema } from 'models/subject';
import { videoPresenterSchema, VideoPresenter } from 'models/videoPresenter';
import { CourseTag } from 'models/courseTag';
import { User } from 'models/user';

export const MAX_OTHER_TAG_COUNT = 3;
export const NO_AUTHOR_ID = 'no_author';
export const systemAdminOnlyFields = ['system', 'videoUrl'];

export enum System {
  QuipperSchool = 'quipperschool',
}

export interface QuipperSchoolCourseMeta {
  assessmentOrTryout?: boolean;
  time_limit?: number;
  level?: string[];
}

const defaultPreviewVideo = {
  original_url: '',
  description: '',
  presenter: '',
};

const defaultMeta = {
  // quipperschool meta fields
  time_limit: '',
};

export const QUIPPER_COURSES_ID = 'quipper';

export interface Course<
  BundleType = Bundle,
  VideoPresenterType = VideoPresenter,
  CourseTagType = CourseTag,
  SubjectType = Subject
> {
  id: string;
  guid: string;
  icon: string;
  name: string;
  description?: string;
  organization_id: string;
  course_set_id?: string;
  published_at: string;
  created_at: string;
  created_by: string;
  errors: string[];
  systems: string[];
  grade_levels: string[];
  level?: string[];
  meta: QuipperSchoolCourseMeta;
  preview_video?: {
    original_url: string;
    description: string;
    presenter?: VideoPresenter;
  };
  bundles: BundleType[];
  video_presenters: VideoPresenterType[];
  course_tags?: CourseTagType[];
  subjects?: SubjectType[];
  limited: boolean;
  limited_to: User[];
  author: boolean;
  accessible: boolean;
  author_id: string;
  school_assessment_course: boolean;
}

export type NormalizedCourse = Course<string, string, string, string>;

export const courseTagSchema = new schema.Entity('courseTags');

export const courseSchema = new schema.Entity(
  'courses',
  {
    bundles: [bundleSchema],
    video_presenters: [videoPresenterSchema],
    course_tags: [courseTagSchema],
    subjects: [subjectSchema],
  },
  {
    processStrategy: course => {
      let processedCourse = course;
      const system = course.systems?.[0];

      if (system) {
        // When API returns meta object with less properties than what's already
        // in the store we still need to update (in this case erase the value)
        // these properties in the store. Since entities reducer ignores absent or
        // properties with undefined values we need to make sure that these
        // properties have default values.
        //
        // For example: when API returns { meta: { level: ['g12'] } } but in
        // the store the course meta is still { meta: { level: ['g12'], time_limit: 12345 }}
        // it means the user removed the value for time_limit so we need to update
        // that value in store

        const preview_video = merge(
          {},
          defaultPreviewVideo,
          course.preview_video,
        );
        processedCourse = { ...processedCourse, preview_video };

        if (system === System.QuipperSchool) {
          const meta = merge({}, defaultMeta, course.meta);
          processedCourse = { ...processedCourse, meta };
        }
      }

      return {
        ...processedCourse,

        // Make sure course always have a bundles property
        ...(processedCourse.bundles ? {} : { bundles: [] }),
      };
    },
  },
);
