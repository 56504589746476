import React, { HTMLAttributes } from 'react';
import { RouteComponentProps } from '@reach/router';
import c from 'classnames';

import Banner from 'components/atoms/Banner';
import PageTitle from 'components/atoms/PageTitle';
import Footer from 'components/organisms/footer/Footer';
import { ErrorName } from 'utils/errors';
import ErrorPage from 'components/pages/errors/ErrorPage';

import './PageTemplate.css';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  pageHeader?: React.ReactNode;
  pageFooter?: React.ReactNode;
  bannerText?: string;
  pageTitleId?: string;
  errorName?: ErrorName;
}

const PageTemplate = ({
  pageHeader,
  pageFooter = <Footer />,
  children,
  bannerText,
  pageTitleId,
  errorName,
  className,
}: Props & RouteComponentProps) => {
  return (
    <div className={c('PageTemplate flex flex-col', className)}>
      {errorName ? (
        <div className="flex-grow flex items-center justify-center">
          <ErrorPage errorName={errorName} />
        </div>
      ) : (
        <>
          {pageTitleId && <PageTitle id={pageTitleId} />}
          {bannerText && (
            <Banner dismissible isVisible={!!bannerText}>
              {bannerText}
            </Banner>
          )}
          {pageHeader}
          <div className="px-32 mb-80 flex-grow">{children}</div>
          {pageFooter}
        </>
      )}
    </div>
  );
};

export default PageTemplate;
