import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';

import { ThunkActionCreator } from 'app/store';
import { deleteResource, get, post, put } from 'utils/api';
import { Organization, organizationSchema } from 'models/organization';
import { addRecords, deleteRecords } from 'slices/entities';
import { courseIconSchema } from 'models/courseIcon';
import { authorSchema, User } from 'models/user';
import {
  buildUrlWithCourseFiltersQuery,
  buildUrlWithTagFiltersQuery,
  FetchCoursesQuery,
  FetchTagsQuery,
} from 'utils/url';
import { courseTagSchema } from 'models/course';
import { CourseTag } from 'models/courseTag';
import { Subject, subjectSchema } from 'models/subject';
import {
  fetchOrganizationDone,
  fetchOrganizationStart,
} from 'slices/organizations';
import { fetchJobStatus } from 'slices/job';

export const fetchOrganization: ThunkActionCreator<string> = organizationId => async dispatch => {
  dispatch(fetchOrganizationStart());
  const res = await get(`/content/organizations/${organizationId}`);

  if (res.status >= 400) {
    throw res.status;
  } else {
    const organization = await res.json();

    dispatch(addRecords(normalize(organization, organizationSchema)));
    dispatch(fetchOrganizationDone());
  }
};

export const fetchCourseIcons: ThunkActionCreator<{
  organizationId: string;
}> = ({ organizationId }) => async dispatch => {
  const res = await get(`/content/organizations/${organizationId}/icon_images`);

  if (res.status >= 400) {
    throw res.status;
  } else {
    const icons = await res.json();
    const normalizedData = normalize(icons, [courseIconSchema]);

    dispatch(addRecords(normalizedData));
  }
};

export const downloadCourses: ThunkActionCreator<{
  organizationId: string;
  includeIds: boolean;
  query?: FetchCoursesQuery;
  retriedJobId?: string;
}> = ({ organizationId, includeIds, query, retriedJobId }) => async () => {
  const url = buildUrlWithCourseFiltersQuery(
    `/content/organizations/${organizationId}/download_courses`,
    query,
    { include_ids: includeIds ? '1' : '', retried_job_id: retriedJobId },
  );
  const res = await post(url);

  if (res.status >= 400) {
    throw res.status;
  }

  return await res.json();
};

export const fetchDownloadCoursesJobs: ThunkActionCreator<
  { ids: string[] } | undefined
> = payload => async () => {
  if (payload !== undefined && payload.ids.length === 0) {
    return;
  }

  const res = await get(
    stringifyUrl(
      {
        url: `/users/download_courses_jobs`,
        query: { job_ids: payload?.ids || [] },
      },
      { arrayFormat: 'bracket' },
    ),
  );

  if (res.status >= 400) {
    throw res.status;
  }

  return await res.json();
};

export const fetchAuthors: ThunkActionCreator<{
  organizationId: string;
}> = ({ organizationId }) => async dispatch => {
  const res = await get(
    `/content/organizations/${organizationId}/show_authors`,
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const authors: User[] = await res.json();
    const normalizedData = normalize(authors, [authorSchema]);
    dispatch(addRecords(normalizedData));
    return authors;
  }
};

export const updateOrganizationTeam: ThunkActionCreator<{
  organizationId: string;
  useTeams: boolean;
}> = ({ organizationId, useTeams }) => async dispatch => {
  const res = await put(`/content/organizations/${organizationId}`, {
    use_teams_in_qcreate: useTeams,
  });

  if (res.status >= 400) {
    throw res.status;
  } else {
    const organization = await res.json();
    const normalizedData = normalize<Organization>(
      organization,
      organizationSchema,
    );
    dispatch(addRecords(normalizedData));
  }
};

export const fetchCourseTags: ThunkActionCreator<{
  organizationId: string;
  query?: FetchTagsQuery;
}> = ({ organizationId, query }) => async dispatch => {
  const res = await get(
    buildUrlWithTagFiltersQuery(
      `/content/organizations/${organizationId}/course_tags`,
      query,
    ),
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const courseTags: CourseTag[] = await res.json();
    const normalizedData = normalize(courseTags, [courseTagSchema]);

    dispatch(addRecords(normalizedData));
    return {
      pageCount: res.headers.get('X-Quipper-Pages'),
      ids: courseTags.map(c => c.id),
    };
  }
};

export const deleteCourseTags: ThunkActionCreator<{
  courseTagIds: string[];
  organizationId: string;
}> = ({ organizationId, courseTagIds }) => async (dispatch, getState) => {
  const res = await deleteResource(
    `/content/organizations/${organizationId}/delete_course_tags`,
    { course_tag_ids: courseTagIds },
  );
  if (res.status >= 400) {
    throw res.status;
  } else {
    const job = await res.json();
    await dispatch(fetchJobStatus(job));

    const courseTagsEntity = getState().entities.courseTags;
    const courseTags = courseTagIds.map(
      (id: string) => courseTagsEntity.byId[id],
    );

    const normalizedData = normalize(courseTags, [courseTagSchema]);
    await dispatch(deleteRecords(normalizedData));
  }
};

export const createCourseTag: ThunkActionCreator<CourseTag> = ({
  organization_id,
  name,
  type,
}) => async () => {
  const res = await post(
    `/content/organizations/${organization_id}/course_tags`,
    { course_tag_name: name, type },
  );

  if (res.status >= 400) {
    throw res.status;
  }
};

export const fetchSubjects: ThunkActionCreator<{
  organizationId: string;
  query?: FetchTagsQuery;
}> = ({ organizationId, query }) => async dispatch => {
  const res = await get(
    buildUrlWithTagFiltersQuery(
      `/content/organizations/${organizationId}/subjects`,
      query,
    ),
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const subjects: Subject[] = await res.json();
    const normalizedData = normalize(
      { id: organizationId, subjects },
      organizationSchema,
    );

    dispatch(addRecords(normalizedData));
    return {
      pageCount: res.headers.get('X-Quipper-Pages'),
      ids: subjects.map(s => s.id),
    };
  }
};

export const fetchCountrySubjects: ThunkActionCreator<{
  organizationId: string;
  query?: FetchTagsQuery;
}> = ({ organizationId, query }) => async dispatch => {
  const res = await get(
    buildUrlWithTagFiltersQuery(
      `/content/organizations/${organizationId}/country_subjects`,
      query,
    ),
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const countrySubjects: Subject[] = await res.json();
    const normalizedData = normalize(
      { id: organizationId, country_subjects: countrySubjects },
      organizationSchema,
    );

    dispatch(addRecords(normalizedData));
    return {
      pageCount: res.headers.get('X-Quipper-Pages'),
      ids: countrySubjects.map(s => s.id),
    };
  }
};

export const deleteSubjects: ThunkActionCreator<{
  subjectIds: string[];
  organizationId: string;
}> = ({ organizationId, subjectIds }) => async (dispatch, getState) => {
  const res = await deleteResource(
    `/content/organizations/${organizationId}/delete_subjects`,
    { subject_ids: subjectIds },
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const job = await res.json();
    await dispatch(fetchJobStatus(job));

    const subjectsEntity = getState().entities.subjects;
    const subjects = subjectIds.map((id: string) => subjectsEntity.byId[id]);

    const normalizedData = normalize(subjects, [subjectSchema]);
    await dispatch(deleteRecords(normalizedData));
  }
};

export const createSubject: ThunkActionCreator<Subject> = ({
  organization_id,
  name,
}) => async () => {
  const res = await post(`/content/organizations/${organization_id}/subjects`, {
    subject_name: name,
  });

  if (res.status >= 400) {
    throw res.status;
  }
};

export interface TagNameResponse {
  valid: boolean;
  suggested_name?: string;
}

export const validateSubjectName: ThunkActionCreator<Partial<Subject>> = ({
  organization_id,
  name,
}) => async () => {
  const res = await get(
    stringifyUrl({
      url: `/content/organizations/${organization_id}/validate_subject`,
      query: { subject_name: name },
    }),
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const { valid }: TagNameResponse = await res.json();
    return { valid };
  }
};

export const validateTagName: ThunkActionCreator<CourseTag> = ({
  organization_id,
  type,
  name,
}) => async () => {
  const res = await get(
    stringifyUrl({
      url: `/content/organizations/${organization_id}/validate_tag`,
      query: { course_tag_name: name, type },
    }),
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const { valid, suggested_name }: TagNameResponse = await res.json();
    return { valid, suggested_name };
  }
};

export interface UpdateRolePayload {
  teamId: string;
  userId: string;
  action: 'update' | 'remove';
}

export const updateRole: ThunkActionCreator<UpdateRolePayload> = ({
  teamId,
  userId,
  action,
}) => async (dispatch, getState) => {
  const res = await post(`/content/teams/${teamId}/${action}_member/${userId}`);

  if (res.status >= 400) {
    throw res.status;
  }

  const selectedId = getState().organizations.selectedId || '';
  dispatch(fetchOrganization(selectedId));
};
