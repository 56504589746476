import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';

import { deleteResource } from 'utils/api';
import { fetchUser } from 'slices/user';
import { User } from 'models/user';
import { clearCookies, getToken } from 'utils/cookies';
import { getUser, fetchUserFailed } from 'selectors/user';
import { getLocale } from 'utils/i18n';

interface Auth {
  logout: () => Promise<void>;
  user: User | null;
  isTokenExpired: boolean;
}

const AuthContext = React.createContext<Auth | undefined>(undefined);

const AuthProvider = (props: { children: React.ReactNode }) => {
  const user = useSelector(getUser);
  const isTokenExpired = useSelector(fetchUserFailed);
  const dispatch = useDispatch();
  const locale = getLocale();

  // useLayoutEffect is intentionally used instead of useEffect so that
  // fetchUser is dispatched before the component renders to avoid "flickering".
  // Not doing this will result to the user momentarily seeing the login page
  // and then the loading page
  useLayoutEffect(() => {
    if (!user && !!getToken()) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  const logout = async () => {
    await deleteResource(`/map/auth`, {
      token: getToken(),
    });

    clearCookies();
    dispatch({ type: 'logout' });
    navigate(`/${locale}`);
  };

  return (
    <AuthContext.Provider value={{ logout, user, isTokenExpired }} {...props} />
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
