import JsCookie from 'js-cookie';

import { getRootDomain } from 'utils/browser';

// Override default encoding/decoding behavior to always perform
// URL encoding. By default, js-cookie will only URL encode when
// it sees certain special characters, which do not necessarily
// occur in our auth tokens.
// Other services like qlink and create-meiko expect auth tokens
// to be URL encoded, so we need to do this for the tokens to be
// interoperable between apps.
const Cookies = JsCookie.withConverter({
  read: value => decodeURIComponent(value),
  write: value => encodeURIComponent(value.toString()),
});

export const SHARED_COOKIE_OPTIONS = {
  domain: getRootDomain(),
};

export const cookies = {
  selectedOrganizationId: {
    name: 'selected_organization_id',
    options: {},
  },
  authToken: {
    name: 'quipper_auth_token',
    options: SHARED_COOKIE_OPTIONS,
  },
};

export const getSelectedOrganizationIdCookies = () =>
  Cookies.get(cookies.selectedOrganizationId.name);
export const setSelectedOrganizationIdCookies = (id: string) => {
  const cookie = cookies.selectedOrganizationId;
  Cookies.set(cookie.name, id, cookie.options);
};

export const getToken = () => Cookies.get(cookies.authToken.name);

export const setToken = (token: string) => {
  const cookie = cookies.authToken;
  Cookies.set(cookie.name, token, cookie.options);
};

export const clearCookies = () => {
  Object.values(cookies).forEach(c => Cookies.remove(c.name, c.options));
};
