import React, { HTMLAttributes } from 'react';
import c from 'classnames';

import './ContentWithSidebarContainer.css';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const ContentWithSidebarContainer = ({ children, className }: Props) => {
  return (
    <div
      id="content-with-sidebar-container-id"
      className={c(
        'ContentWithSidebarContainer flex flex-grow overflow-hidden relative',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default ContentWithSidebarContainer;
