import React, { useEffect } from 'react';

import { ErrorName } from 'utils/errors';

const useHandleAsyncError = (error?: ErrorName) => {
  // Work-around for throwing errors in asynchronous functions
  // This will be catched in ErrorBoundary
  // See https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
  const useAsyncError = () => {
    const [, setError] = React.useState();
    return React.useCallback(
      e => {
        setError(() => {
          throw e;
        });
      },
      [setError],
    );
  };

  // Must declare another variable to use the function
  const throwError = useAsyncError();

  useEffect(() => {
    if (error) throwError(error);
  }, [error, throwError]);
};

export default useHandleAsyncError;
