import { schema } from 'normalizr';

import { Section } from 'models/section';
import { VideoPresenter } from 'models/videoPresenter';

export type ChapterType = 'text' | 'video';

export interface Chapter {
  chapter_type: ChapterType;
  course_id: string;
  embed?: string;
  id: string;
  lesson_id: string;
  name: string; // could be an empty string
  original_url?: string;
  last_saved_at?: string;
  published_at?: string;
  sections: Section[];
  show_chapter_code_form: boolean;
  title: string;
  topic_id: string;
  video_url?: string;
  workflow_state: string; // "in_progress" | "accepted"
  video_duration_seconds?: number;
  description?: string;
  errors: string[];
  presenter?: VideoPresenter;
  author: boolean;

  // The result of squashing sections. This property is populated in chapter
  // selector
  content?: string;
}

export const chapterSchema = new schema.Entity('chapters');
