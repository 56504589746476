import React from 'react';

import { ReactComponent as Logo } from 'assets/icons/full-logo.svg';
import FooterLinks from 'components/organisms/footer/FooterLinks';

const StyledLoginPage = ({
  children: loginForm,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="flex h-screen">
      <div className="bg-grey-plus2 w-50p flex flex-col h-auto">
        <div className="flex flex-grow justify-center items-center">
          <Logo />
        </div>
        <div className="text-white px-24 h-48 text-12 font-bold">
          <span>&copy;{new Date().getFullYear()} Quipper Limited</span>
        </div>
      </div>

      <div className="bg-white w-50p flex flex-col">
        <div className="flex flex-grow justify-center items-center">
          {loginForm}
        </div>
        <div className="flex justify-end text-grey-plus2 px-24 h-48">
          <FooterLinks />
        </div>
      </div>
    </div>
  );
};

export default StyledLoginPage;
