import React from 'react';
import classNames from 'classnames';

import FooterLinks from 'components/organisms/footer/FooterLinks';

const Footer = ({ className }: { className?: string }) => {
  return (
    <div
      className={classNames(
        'h-96 border-t border-grey-minus1 bg-grey-minus3 text-grey-plus1 text-12',
        className,
      )}
    >
      <div className="px-32 h-full flex items-center justify-between">
        <div className="flex items-center">
          <span>&copy;{new Date().getFullYear()} Quipper Limited</span>
        </div>
        <FooterLinks />
      </div>
    </div>
  );
};

export default Footer;
