import {
  useEffect,
  useReducer,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import * as Redux from 'react-redux';
import { omitBy, isEmpty } from 'lodash-es';

import { getErrorName, ErrorName } from 'utils/errors';
export interface State {
  pending: boolean;
  failed: boolean;
  done: boolean;
  succeeded: boolean;
  status?: number;
}
enum ActionType {
  Start = 'START',
  Fail = 'FAIL',
  Finish = 'FINISH',
  Reset = 'RESET',
}

export type UseAsyncProps<R> = State & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  run: (newPayload?: any) => void;
  resetState: () => void;
  errorName?: ErrorName;
  result: R;
};

interface Action {
  type: ActionType;
  succeeded?: boolean;
  status?: number;
}

const start = { type: ActionType.Start };
const fail = (status?: number) => ({ type: ActionType.Fail, status });
const finish = () => ({
  type: ActionType.Finish,
});
const reset = { type: ActionType.Reset };

const initialState: State = {
  pending: false,
  failed: false,
  done: false,
  succeeded: false,
};

const reducer = (state: State, { type, status }: Action): State => {
  switch (type) {
    case 'START':
      return { ...initialState, pending: true };
    case 'FAIL':
      return {
        ...state,
        done: true,
        failed: true,
        pending: false,
        status,
      };
    case 'FINISH':
      return { ...state, succeeded: true, done: true, pending: false };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

type Payload = Record<string, unknown> | string | 'NO_PARAM';
interface Options {
  defer: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAsync = <R = any>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thunkActionCreator: any,
  initialPayload: Payload = 'NO_PARAM',
  passedOptions: Options = { defer: false },
) => {
  const options = useRef(passedOptions);
  const reduxDispatch = Redux.useDispatch();
  const [{ pending, failed, done, succeeded, status }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  // Store the payload as a string so we can later check if it changed. We can't
  // store it as is because if the payload is an object then comparing it to
  // itself will always evaluate to false
  const payloadSignature = useRef(JSON.stringify(initialPayload));

  const [payload, setPayload] = useState(initialPayload);
  const [result, setResult] = useState<R>();

  const [defer, setDefer] = useState(options.current.defer);
  const [count, setCount] = useState(0);

  const [errorName, setErrorName] = useState<ErrorName>();
  const setError = useState(null)[1];

  const hookName = options.current.defer ? 'useDeferredAsync' : 'useAsync';
  const actionName = thunkActionCreator.name;

  const resetState = useCallback(() => {
    reduxDispatch({ type: `${hookName}/${actionName}/resetState` });
    dispatch(reset);
  }, [reduxDispatch, dispatch, actionName, hookName]);

  const resetResult = useCallback(() => {
    setResult(undefined);
  }, [setResult]);

  const run = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () => (newPayload?: any) => {
      if (newPayload) {
        setPayload(newPayload);
      } else {
        // We still want to run the effect that dispatches the request so we
        // change one of its dependencies
        setCount(c => c + 1);
      }

      setDefer(false);
    },
    [],
  );

  const validatePayload = useCallback(() => {
    if (payload === 'NO_PARAM') {
      return true;
    }

    // Filter out {}, ''
    if (isEmpty(payload)) {
      return false;
    }

    if (typeof payload !== 'string') {
      // Remove keys with null, undefined, or empty string values so we avoid
      // sending a request when not all required params are present. For
      // example, { foo: 'foo', bar: undefined } becomes { foo: 'foo' } so it is
      // treated as an invalid payload
      const requiredParamsCount = Object.keys(payload).length;
      const cleanedPayload = omitBy(
        payload,
        value =>
          value === undefined ||
          value === null ||
          (typeof value === 'string' && (value as string).length < 1),
      );
      const cleanedPayloadParamsCount = Object.keys(cleanedPayload).length;
      return (
        !isEmpty(cleanedPayload) &&
        requiredParamsCount === cleanedPayloadParamsCount
      );
    }

    return true;
  }, [payload]);

  const isPayloadValid = useMemo(() => validatePayload(), [validatePayload]);

  // Dispatch the async action when the payload passed to useAsync changes
  useEffect(() => {
    if (JSON.stringify(initialPayload) !== payloadSignature.current) {
      payloadSignature.current = JSON.stringify(initialPayload);
      setPayload(initialPayload);
    }
  }, [initialPayload]);

  useEffect(() => {
    let didCancel = false;

    const dispatchAsyncAction = async () => {
      reduxDispatch({ type: `${hookName}/${actionName}/start` });
      dispatch(start);

      const _payload = payload === 'NO_PARAM' ? undefined : payload;

      try {
        const data = await reduxDispatch(thunkActionCreator(_payload));

        if (!didCancel) {
          setResult(data);
          reduxDispatch({ type: `${hookName}/${actionName}/done` });
          dispatch(finish());
        }
      } catch (e) {
        let thrownError;
        let _errorName = ErrorName.UnknownError;

        if (typeof e === 'object' && e.status && e.errorName) {
          thrownError = getErrorName(e.status);
          _errorName = e.errorName;
        } else {
          thrownError = getErrorName(e);
          _errorName = thrownError;
        }

        setErrorName(_errorName);

        if (thrownError === ErrorName.UnknownError) {
          // Call set state to work around the fact that error boundaries can't
          // see async errors. See https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
          setError(() => {
            // Re-throw errors that we don't explicitly handle so error boundary can
            // handle them and send them to Sentry for tracking
            throw e;
          });
        }

        if (!didCancel) {
          reduxDispatch({ type: `${hookName}/${actionName}/failed` });
          dispatch(fail(e));
        }
      }
    };

    // Do not dispatch async action until the first run(). Also, for deferred
    // async we don't validate the payload because initialPayload is not
    // passed so we don't have a basis for validity
    if (options.current.defer && !defer) {
      dispatchAsyncAction();
    }

    if (!options.current.defer && isPayloadValid) {
      dispatchAsyncAction();
    }

    return () => {
      didCancel = true;
    };
  }, [
    dispatch,
    reduxDispatch,
    thunkActionCreator,
    defer,
    isPayloadValid,
    payload,
    // count serves as another way to trigger the effect if payload does not
    // change. It's used when option.deferred is true so calling run without a
    // payload works
    // eslint-disable-next-line react-hooks/exhaustive-deps
    count,
    hookName,
    actionName,
    setError,
  ]);

  return {
    pending,
    failed,
    done,
    succeeded,
    run,
    result,
    status,
    errorName,
    resetState,
    resetResult,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDeferredAsync = <R = any>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thunkActionCreator: any,
  payload: Payload = 'NO_PARAM',
) => useAsync<R>(thunkActionCreator, payload, { defer: true });
