import { useState, useMemo } from 'react';
import { debounce } from 'lodash-es';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { hasMatch } from 'utils/filter';

const useFilter = <T>(objects: T[], filterBy: keyof T) => {
  const [filtered, setFiltered] = useState(objects);
  const [filter, _setFilter] = useState('');

  // useDeepCompareEffect to only update when the contents of the array changes
  // not the array object itself. useEffect will cause an infinite loop here
  useDeepCompareEffect(() => {
    setFiltered(objects);
  }, [objects]);

  const debouncedSetFilter = useMemo(
    () =>
      debounce((s: string) => {
        setFiltered(
          objects.filter(o => hasMatch((o[filterBy] as unknown) as string, s)),
        );
      }, 500),
    [setFiltered, objects, filterBy],
  );

  const setFilter = (newFilter: string) => {
    debouncedSetFilter(newFilter);
    _setFilter(newFilter);
  };

  return { filtered, filter, setFilter };
};

export default useFilter;
