import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { configureScope as configureScopeSentry } from '@sentry/browser';
import { denormalize, normalize } from 'normalizr';

import { ThunkActionCreator } from 'app/store';
import { get } from 'utils/api';
import { setSelectedOrganizationIdCookies } from 'utils/cookies';
import { Organization, organizationSchema } from 'models/organization';
import { getRole } from 'utils/organization';
import { addRecords } from 'slices/entities';

interface OrganizationsState {
  selectedId?: string;
  isFetchingOrg: boolean;
}

export const initialState: OrganizationsState = { isFetchingOrg: false };

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    selectOrganizationDone(state, { payload: id }: PayloadAction<string>) {
      state.selectedId = id;
    },
    fetchOrganizationStart(state) {
      state.isFetchingOrg = true;
    },
    fetchOrganizationDone(state) {
      state.isFetchingOrg = false;
    },
  },
});

export const {
  selectOrganizationDone,
  fetchOrganizationStart,
  fetchOrganizationDone,
} = slice.actions;

export const fetchOrganizations: ThunkActionCreator = () => async dispatch => {
  const res = await get('/content/organizations');

  if (res.status >= 400) {
    throw res.status;
  } else {
    const orgs: Organization[] = await res.json();
    const normalizedOrgs = normalize(orgs, [organizationSchema]);
    dispatch(addRecords(normalizedOrgs));
  }
};

export const selectOrganization: ThunkActionCreator<string> = orgId => async (
  dispatch,
  getState,
) => {
  setSelectedOrganizationIdCookies(orgId);

  const orgFromState = getState().entities.organizations.byId[orgId];
  const organization = denormalize(orgFromState, organizationSchema, {});

  await dispatch(selectOrganizationDone(orgId));

  configureScopeSentry(scope => {
    scope.setTags({
      current_organization_id: orgId,
      user_role: getRole(organization),
    });
  });
};

export default slice.reducer;
