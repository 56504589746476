import { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate, globalHistory, useLocation } from '@reach/router';
import { stringifyUrl, parse } from 'query-string';
import { debounce } from 'lodash-es';

interface Filters {
  [key: string]: string | string[] | undefined;
}

const withoutPage1 = ([key, value]: [string, string]) =>
  !(key === 'page' && value === '1');

const buildPath = (pathname: string, filters: Filters) => {
  const queryStringFilters = Object.fromEntries(
    Object.entries(filters).filter(withoutPage1),
  );
  return stringifyUrl(
    { url: pathname, query: queryStringFilters },
    { arrayFormat: 'bracket', skipEmptyString: true },
  );
};

interface UpdateFiltersOptions {
  debounce?: boolean;
  reset?: boolean;
}
const useUrlQuery = <TFilters extends Filters>(
  callback: (filters: Partial<TFilters>) => void,
  defaultQueries: Partial<TFilters>,
): [
  Partial<TFilters>,
  (filtersNew: Partial<TFilters>, options?: UpdateFiltersOptions) => void,
] => {
  const navigate = useNavigate();
  const { pathname, search: queryString } = useLocation();
  const originalPathname = pathname;

  const filtersInitial = {
    ...defaultQueries,
    ...(parse(queryString, {
      arrayFormat: 'bracket',
    }) as Partial<TFilters>),
  };
  const [filters, setFilters] = useState<Partial<TFilters>>(filtersInitial);

  const commitFilterUpdate = useCallback(
    (filtersNext: Partial<TFilters>) => {
      callback(filtersNext);
      navigate(buildPath(pathname, filtersNext), {
        state: { isFilterUpdatePending: true },
      });
    },
    [callback, navigate, pathname],
  );
  const commitFilterUpdateDebounced = useMemo(
    () => debounce(commitFilterUpdate, 500),
    [commitFilterUpdate],
  );

  const updateFilters = useCallback(
    (
      newFilters: Partial<TFilters>,
      options = { debounce: false, reset: false },
    ) => {
      setFilters(state => {
        const filtersNext = options.reset
          ? newFilters
          : { ...state, ...newFilters };

        if (options.debounce) {
          commitFilterUpdateDebounced(filtersNext);
        } else {
          commitFilterUpdate(filtersNext);
        }

        return filtersNext;
      });
    },
    [commitFilterUpdate, commitFilterUpdateDebounced],
  );

  // Check if the current filter state is in sync with the query string
  // in the current address. If it is not, update it.
  useEffect(() => {
    const unsubscribe = globalHistory.listen(({ action, location }) => {
      // If the pathname changes, do not execute the callback
      // ref: https://github.com/quipper/quipper/issues/25382
      if (originalPathname !== location.pathname) return;

      // Sync only when some external force triggers navigation:
      // 1. A POP action means browser forward/back buttons were used
      // 2. isFilterUpdatePending not being set in location state means
      //    some external navigate() call modified the URL
      if (action === 'POP' || !location.state.isFilterUpdatePending) {
        const urlFilters = parse(location.search) as Partial<TFilters>;

        setFilters(urlFilters);
        callback(urlFilters);
      }
    });

    return () => unsubscribe();
  }, [callback, filters, originalPathname]);

  return [filters, updateFilters];
};

export default useUrlQuery;
