import classNames from 'classnames';
import React from 'react';
import { Link, LinkProps, Match } from '@reach/router';

type SVGIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

const defaultClassName = `
  flex flex-col items-center
  text-10 font-bold hover:text-primary-plus1
  relative
`;

const NavbarLink = React.forwardRef((
  {
    Icon,
    ActiveIcon,
    children,
    className,
    to,
    matcher,
    ...props
  }: {
    Icon: SVGIcon;
    ActiveIcon: SVGIcon;
    matcher?: (pathname: string) => void;
    to: string;
  } & LinkProps<Record<string, unknown>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) => (
  <Match path={to}>
    {({ match, location }) => {
      const isPathMatched = matcher && matcher(location.pathname);
      const isActive = !!match || isPathMatched;
      const NavbarIcon = isActive ? ActiveIcon : Icon;

      return (
        <Link
          ref={ref}
          to={to}
          className={classNames(
            defaultClassName,
            className,
            isActive ? 'text-primary-plus1' : 'text-grey-plus1',
          )}
          {...props}
        >
          <NavbarIcon width="24" height="24" className="inline fill-current" />
          {children}
        </Link>
      );
    }}
  </Match>
));

export default NavbarLink;
