import { useRef, useEffect } from 'react';

interface Props {
  pollIntervalInMilliseconds?: number;
  callback: () => void;
}

const DEFAULT_POLL_INTERVAL = 10000; // 10 seconds

const usePoll = ({
  pollIntervalInMilliseconds = DEFAULT_POLL_INTERVAL,
  callback,
}: Props) => {
  const poll = useRef<number>();

  useEffect(() => {
    return () => {
      clearInterval(poll.current);
      poll.current = undefined;
    };
  }, []);

  const startPolling = () => {
    if (!poll.current) {
      poll.current = window.setInterval(callback, pollIntervalInMilliseconds);
    }
  };

  const stopPolling = () => {
    if (poll.current) {
      clearInterval(poll.current);
      poll.current = undefined;
    }
  };

  return { startPolling, stopPolling, isPolling: !!poll.current };
};

export default usePoll;
