import React, { useState, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import { Input } from 'components/atoms/Input';
import { ReactComponent as PasswordHiddenIcon } from 'assets/icons/invisible.svg';
import { ReactComponent as PasswordShownIcon } from 'assets/icons/visible.svg';

import './PasswordInput.css';

const VisibilityIcon = ({ visible }: { visible: boolean }) => {
  const Icon = visible ? PasswordShownIcon : PasswordHiddenIcon;
  return <Icon height="24" width="24" className="fill-current" />;
};

const PasswordInput = ({
  className,
  disabled,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <span className={classNames('PasswordInput relative', className)}>
      <Input
        type={showPassword ? 'text' : 'password'}
        disabled={disabled}
        {...props}
        className="w-full pr-40"
      />

      <span
        onClick={() => !disabled && setShowPassword(state => !state)}
        onKeyDown={e => {
          if (e.key === 'Enter' && !disabled) {
            setShowPassword(state => !state);
          }
        }}
        className="text-grey absolute inset-y-center right-8 select-none"
        data-testid="password-visibility-toggle"
      >
        <VisibilityIcon visible={showPassword} />
      </span>
    </span>
  );
};

export default PasswordInput;
