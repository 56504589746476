import React from 'react';
import c from 'classnames';
import { MenuItemProps } from 'rc-menu/lib/MenuItem';
import { MenuItem as RcMenuItem } from 'rc-menu';

const paddingClasses = 'px-16 py-12';

const MenuItem = ({
  href,
  target,
  className,
  linkClassName,
  isSelected = false,
  children,
  isDisabled = false,
  ...props
}: {
  href?: string;
  target?: string;
  linkClassName?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
} & MenuItemProps) => {
  const relAttributes =
    target === '_blank' ? { rel: 'noopener noreferrer' } : {};
  return (
    <RcMenuItem
      className={c(
        paddingClasses,
        className,
        !isDisabled && !isSelected
          ? 'cursor-pointer hover:bg-primary-minus2 hover:text-grey-plus2'
          : 'cursor-default',
        {
          'bg-grey-minus2': isSelected,
        },
      )}
      {...props}
    >
      {href ? (
        <a
          className={c('-mx-16 -my-12', paddingClasses, linkClassName)}
          href={href}
          target={target}
          {...relAttributes}
        >
          {children}
        </a>
      ) : (
        children
      )}
    </RcMenuItem>
  );
};

export default MenuItem;
