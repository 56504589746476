export enum SectionContentType {
  Passage = 'Passage',
  Video = 'SectionContent::ExternalVideo',
}

export interface Section {
  content: string;
  content_html?: string;
  id?: string;
  math_section?: boolean;
  media_url?: string;
  mime_type?: string;
  section_content_id?: string;
  section_content_type?: SectionContentType;
}

export interface Category extends Section {
  temporary_category_id?: string;
  hasFocus?: boolean;
}
export interface MatchingChoice extends Section {
  temporary_matching_choice_id?: string;
  hasFocus?: boolean;
}
