import { normalize } from 'normalizr';
import jsonToFormData from 'json-form-data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stringifyUrl } from 'query-string';

import { ThunkActionCreator } from 'app/store';
import { get, post, put, deleteResource, patch } from 'utils/api';
import { topicSchema, Topic } from 'models/topic';
import { addRecords, deleteRecords } from 'slices/entities';
import {
  documentSchema,
  linkAttachmentSchema,
  LinkAttachment,
} from 'models/document';
import { fetchBundle } from 'slices/bundles';
import { getSelectedOrganizationIdCookies } from 'utils/cookies';
import { selectOrganization } from 'slices/organizations';
import { questionSchema } from 'models/question';
import { getQuestions } from 'selectors/questions';

interface TopicsState {
  selectedTopicId?: string;
}

const initialState: TopicsState = {};

const slice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    selectTopic(state, { payload: id }: PayloadAction<string>) {
      state.selectedTopicId = id;
    },
  },
});

export const { selectTopic } = slice.actions;

export interface CreateTopicPayload {
  bundleId: string;
  name: string;
}

export interface MoveTopicPayload {
  newBundleId: string;
  topicId: string;
}

export const createTopic: ThunkActionCreator<CreateTopicPayload> = ({
  bundleId,
  name,
}) => {
  return async dispatch => {
    const res = await post(`/content/topics`, {
      bundle_id: bundleId,
      name,
    });
    if (res.status >= 400) {
      throw res.status;
    } else {
      const topic: Topic = await res.json();
      const normalizedData = normalize(topic, topicSchema);
      dispatch(addRecords(normalizedData));
      return topic.id;
    }
  };
};

export const updateTopic: ThunkActionCreator<Partial<Topic>> = ({
  id,
  name,
  shuffle_answers,
  shuffle_questions,
  questions_label,
}) => async dispatch => {
  const res = await put(`/content/topics/${id}`, {
    name,
    shuffle_answers,
    shuffle_questions,
    questions_label,
  });

  if (res.status >= 400) {
    throw res.status;
  } else {
    const topic: Topic = await res.json();
    const normalizedData = normalize(topic, topicSchema);
    dispatch(addRecords(normalizedData));
  }
};

export const moveTopic: ThunkActionCreator<MoveTopicPayload> = ({
  newBundleId,
  topicId,
}) => async (dispatch, useState) => {
  const res = await patch(`/content/topics/${topicId}`, {
    bundle_id: newBundleId,
  });

  if (res.status >= 400) {
    throw res.status;
  } else {
    const topic = useState().entities.topics.byId[topicId];
    dispatch(fetchBundle({ id: topic.bundle_id }));
  }
};

type FetchTopicParams = {
  id: string;
  exclude?: 'chapters' | 'questions';
  setOrg?: boolean;
};

export const fetchTopic: ThunkActionCreator<FetchTopicParams> = ({
  id,
  setOrg = false,
  exclude = '',
}) => async dispatch => {
  const res = await get(
    stringifyUrl(
      { url: `/content/topics/${id}`, query: { exclude } },
      { skipEmptyString: true },
    ),
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    const topic: Topic = await res.json();
    const normalizedData = normalize(topic, topicSchema);
    dispatch(addRecords(normalizedData));
    dispatch(selectTopic(topic.id));

    const orgId = topic.organization_id;

    if (setOrg && orgId && orgId !== getSelectedOrganizationIdCookies()) {
      dispatch(selectOrganization(topic.organization_id));
    }

    return topic.id;
  }
};

export const fetchTopicQuestions: ThunkActionCreator<FetchTopicParams> = (
  props: FetchTopicParams,
) => async dispatch => {
  await dispatch(fetchTopic({ ...props, exclude: 'chapters' }));
};

export const fetchTopicChapters: ThunkActionCreator<FetchTopicParams> = (
  props: FetchTopicParams,
) => async dispatch => {
  await dispatch(fetchTopic({ ...props, exclude: 'questions' }));
};

export const deleteTopic: ThunkActionCreator<string> = topicId => async (
  dispatch,
  getState,
) => {
  const res = await deleteResource(`/content/topics/${topicId}`);

  if (res.status >= 400) {
    throw res.status;
  } else {
    const topic = getState().entities.topics.byId[topicId];
    const normalizedData = normalize(topic, topicSchema);

    await dispatch(deleteRecords(normalizedData));
  }
};

interface DeleteNotePayload {
  topicId: string;
  noteId: string;
}

export const deleteNote: ThunkActionCreator<DeleteNotePayload> = ({
  topicId,
  noteId,
}) => async (dispatch, getState) => {
  const res = await deleteResource(`/map/topics/${topicId}/delete_notes`, {
    note_id: noteId,
  });

  if (res.status >= 400) {
    throw res.status;
  } else {
    const document = getState().entities.documents.byId[noteId];

    const normalizedData = normalize(document, documentSchema);
    await dispatch(deleteRecords(normalizedData));
  }
};

interface AddLinkAttachmentPayload {
  topicId: string;
  name: string;
  url: string;
}

interface AddLinkAttachmentResponse extends LinkAttachment {
  lesson_id: string;
}

export const addLinkAttachment: ThunkActionCreator<AddLinkAttachmentPayload> = ({
  topicId,
  name,
  url,
}) => async dispatch => {
  const res = await post(`/content/topics/${topicId}/notes/create_link`, {
    name,
    url,
  });

  if (res.status >= 400) {
    throw res.status;
  } else {
    const {
      lesson_id,
      ...newLinkAttachment
    } = (await res.json()) as AddLinkAttachmentResponse;

    const updatedTopic = normalize(
      {
        id: topicId,
        lesson: {
          id: lesson_id,
          link_attachments: [newLinkAttachment],
        },
      },
      topicSchema,
    );

    await dispatch(
      addRecords({
        ...updatedTopic,
        // Concat chapters too since normalized lesson object always has a
        // chapters property which is [] in this case (see lessonSchema
        // processStrategy). Otherwise, the lesson chapters will become empty.
        options: { concatKeys: ['link_attachments', 'chapters'] },
      }),
    );
  }
};

interface DeleteLinkAttachmentPayload {
  topicId: string;
  id: string;
}

export const deleteLinkAttachment: ThunkActionCreator<DeleteLinkAttachmentPayload> = ({
  topicId,
  id,
}) => async dispatch => {
  const res = await deleteResource(
    `/content/topics/${topicId}/notes/delete_link`,
    {
      link_id: id,
    },
  );

  if (res.status >= 400) {
    throw res.status;
  } else {
    await dispatch(deleteRecords(normalize({ id }, linkAttachmentSchema)));
  }
};

export const fetchTextChapterGenerationStatus: ThunkActionCreator<{
  lessonId: string;
}> = ({ lessonId }) => async () => {
  const res = await get(
    `/content/lessons/${lessonId}/text_chapter_generation_status`,
  );

  if (res.status >= 400) {
    throw res.status;
  }

  const json = await res.json();
  return json.in_progress;
};

interface UploadTextChapterPayload {
  topicId: string;
  file: File;
}

export const uploadTextChapter: ThunkActionCreator<UploadTextChapterPayload> = ({
  topicId,
  file,
}) => async dispatch => {
  // fetch S3 presigned POST
  const fetchUploadFormValuesRes = await get(
    `/map/topics/${topicId}/upload_form_values.json`,
  );

  if (fetchUploadFormValuesRes.status >= 400) {
    throw fetchUploadFormValuesRes.status;
  }

  const { url, form_fields: fields } = await fetchUploadFormValuesRes.json();

  // upload file to S3
  const s3UploadRes = await fetch(url, {
    method: 'POST',
    // It's important that the file is last or else S3 returns the error:
    // "Bucket POST must contain a field named 'key'"
    body: jsonToFormData({ ...fields, file }),
  });

  if (s3UploadRes.status >= 400) {
    throw s3UploadRes.status;
  }

  const xml = await s3UploadRes.text();
  const parser = new DOMParser();
  const parsed = parser.parseFromString(xml, 'text/html');
  const key = parsed.getElementsByTagName('Key')[0].childNodes[0].nodeValue;

  const res = await post(`/map/topics/${topicId}/upload_chapters`, {
    file_key: key,
  });

  if (res.status >= 400) {
    throw res.status;
  }

  const uploadChaptersResponse = await res.json();
  const lessonId = uploadChaptersResponse.lesson_id;

  const isInProgress = await dispatch(
    fetchTextChapterGenerationStatus({
      lessonId,
    }),
  );

  const updatedTopic = normalize(
    {
      id: topicId,
      lesson: {
        id: lessonId,
        file_conversion_in_progress: isInProgress,
      },
    },
    topicSchema,
  );

  await dispatch(
    // Concat chapters since normalized lesson object always has a chapters
    // property which is [] in this case (see lessonSchema processStrategy).
    // Otherwise, the lesson chapters will become empty
    addRecords({ ...updatedTopic, options: { concatKeys: ['chapters'] } }),
  );

  return { willPollTextChapters: isInProgress };
};

export const reorderChapters: ThunkActionCreator<{
  topicId: string;
  orderedChapterIds: string[];
}> = ({ topicId, orderedChapterIds }) => async () => {
  const res = await put(`/content/topics/${topicId}/chapters/reorder.json`, {
    order: orderedChapterIds,
  });

  if (res.status >= 400) {
    throw res.status;
  }
};

export const reorderQuestions: ThunkActionCreator<{
  topicId: string;
  orderedQuestionIds: string[];
}> = ({ topicId, orderedQuestionIds }) => async (dispatch, getState) => {
  const res = await put(`/map/topics/${topicId}/questions/reorder.json`, {
    order: orderedQuestionIds,
  });

  if (res.status >= 400) {
    throw res.status;
  }

  const questions = getQuestions(topicId)(getState()).slice();

  const questionsWithNewPositions = orderedQuestionIds.map((id, position) => {
    // Update topic questions succeeding_question_position and preceding_question_position
    // Since this value is not returned from response
    // We should update the positions in frontend for the linked questions
    const question = questions.find(q => q.id == id);
    let preceding_question_position, succeeding_question_position;
    if (question?.preceding_question_position) {
      preceding_question_position = position;
    }

    if (question?.succeeding_question_position) {
      succeeding_question_position = position + 2;
    }

    return {
      id,
      position,
      preceding_question_position,
      succeeding_question_position,
    };
  });

  const normalizedData = normalize(questionsWithNewPositions, [questionSchema]);
  await dispatch(addRecords(normalizedData));
};

export const reorder: ThunkActionCreator<{
  bundleId: string;
  orderedTopicIds: string[];
}> = ({ bundleId, orderedTopicIds }) => async _ => {
  const res = await put(`/content/topics/reorder.json`, {
    bundle_id: bundleId,
    order: orderedTopicIds,
  });

  if (res.status >= 400) {
    throw res.status;
  }
};

export default slice.reducer;
