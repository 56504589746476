import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { RootState } from 'app/rootReducer';
import { chapterSchema } from 'models/chapter';
import { squashSections } from 'utils/content';

export const getChapterEntitySlice = (state: RootState) =>
  state.entities.chapters;

export const getChapter = (id: string) =>
  createSelector(getChapterEntitySlice, ({ byId: chapters }) => {
    const chapter = chapters[id];

    if (chapter) {
      const denormalizedChapter = denormalize(chapter, chapterSchema, {
        chapters,
      });

      return {
        ...denormalizedChapter,
        content: squashSections(denormalizedChapter.sections),
      };
    }
  });

export const getChapterLastSavedAt = createSelector(
  getChapterEntitySlice,
  (_: RootState, id: string) => id,
  ({ byId: chapters }, id) => {
    const chapter = chapters[id];

    if (!chapter) {
      return;
    }

    return chapter.last_saved_at;
  },
);
