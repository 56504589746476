import { getToken } from 'utils/cookies';

export const buildDefaultHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ...(getToken() ? { Authorization: `Token ${getToken()}` } : {}),
});

enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

type RequestBody = FormData | string | unknown | undefined;

const buildPayload = (
  method: HTTPMethod,
  body: RequestBody,
  headers = {},
): RequestInit => {
  const isFormData = body instanceof FormData;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultHeaders: any = {
    Accept: 'application/json',
    ...(getToken() ? { Authorization: `Token ${getToken()}` } : {}),
  };

  let _body: string | FormData | undefined;

  if (isFormData) {
    _body = body as FormData;
  } else {
    defaultHeaders['Content-Type'] = 'application/json';
    _body = JSON.stringify(body);
  }

  return {
    // Need to specify explicitly. See https://github.com/github/fetch#sending-cookies
    credentials: 'same-origin',

    method,
    headers: { ...defaultHeaders, ...headers },
    ...(_body ? { body: _body } : {}),
  };
};

export const get = (path: string, headers: Record<string, unknown> = {}) => {
  const payload = buildPayload(HTTPMethod.GET, undefined, headers);
  return fetch(path, payload);
};

export const post = (
  path: string,
  body: RequestBody = {},
  headers: Record<string, unknown> = {},
) => {
  const payload = buildPayload(HTTPMethod.POST, body, headers);
  return fetch(path, payload);
};

export const put = (
  path: string,
  body: RequestBody = {},
  headers: Record<string, unknown> = {},
  signal?: AbortSignal,
) => {
  const payload = buildPayload(HTTPMethod.PUT, body, headers);
  return fetch(path, { ...payload, signal });
};

export const deleteResource = (
  path: string,
  body: RequestBody = undefined,
  headers: Record<string, unknown> = {},
) => {
  const payload = buildPayload(HTTPMethod.DELETE, body, headers);
  return fetch(path, payload);
};

export const patch = (
  path: string,
  body: RequestBody = {},
  headers: Record<string, unknown> = {},
) => {
  const payload = buildPayload(HTTPMethod.PATCH, body, headers);
  return fetch(path, payload);
};

export const handlePromise = (req: Promise<Response>) => {
  return req.then(res => [null, res]).catch(err => [err]);
};
