import React from 'react';
import classNames from 'classnames';

const Separator = ({ className }: { className?: string }) => (
  <hr
    className={classNames('border-t border-grey-minus2', className)}
    data-testid="separator"
  />
);

export default Separator;
