import { useState, useCallback } from 'react';

interface State<T = undefined> {
  isOpen: boolean;
  modalProps?: T;
}

const useModal = <T>() => {
  const [state, setState] = useState<State<T>>({ isOpen: false });

  return {
    ...state,
    close: useCallback(
      () => setState(() => ({ isOpen: false, modalProps: undefined })),
      [],
    ),
    open: useCallback(
      (modalProps?: T) => setState(() => ({ isOpen: true, modalProps })),
      [],
    ),
  };
};

export default useModal;
