import React from 'react';
import { useIntl } from 'react-intl';

import { getLocale } from 'utils/i18n';
import LanguageSwitcher from 'components/molecules/LanguageSwitcher';

const Link = ({ destUrl, textId }: { destUrl: string; textId: string }) => {
  const { formatMessage: f } = useIntl();

  return (
    <a
      className="hover:underline ml-32"
      href={destUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      {f({ id: textId })}
    </a>
  );
};

const FooterLinks = () => {
  return (
    <div className="flex items-start">
      <LanguageSwitcher />
      <Link
        destUrl={`https://www.quipper.com/${getLocale()}/terms/`}
        textId="footer.terms"
      />
      <Link
        destUrl={`https://www.quipper.com/${getLocale()}/privacy/`}
        textId="footer.privacyPolicy"
      />
      <Link
        destUrl="https://www.quipper.com/contacts/"
        textId="footer.contactUs"
      />
    </div>
  );
};

export default FooterLinks;
