import React from 'react';
import { useIntl } from 'react-intl';

import Modal from 'components/organisms/Modal';
import { Button } from 'components/atoms/Button';
import { useUnsavedChanges } from 'context/UnsavedChangesContext';

const UnsavedChangesModal = ({
  isOpen,
  onCancel,
  onSave,
}: {
  isOpen: boolean;
  onSave: () => void;
  onCancel: () => void;
}) => {
  const { formatMessage: f } = useIntl();
  const { setIsModalOpen } = useUnsavedChanges();

  React.useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen, setIsModalOpen]);

  return (
    <Modal
      isOpen={isOpen}
      className="top-20p"
      title={f({ id: 'form.unsavedChanges.title' })}
    >
      <div className="flex flex-col items-center m-32">
        <p>{f({ id: 'form.unsavedChanges.description.p1' })}</p>
        <p>{f({ id: 'form.unsavedChanges.description.p2' })}</p>
      </div>

      <div className="flex justify-center mb-16 gap-8">
        <Button secondary size="small" className="min-w-128" onClick={onCancel}>
          {f({ id: 'form.unsavedChanges.leave' })}
        </Button>
        <Button size="small" onClick={onSave}>
          {f({ id: 'form.unsavedChanges.stay' })}
        </Button>
      </div>
    </Modal>
  );
};

export default UnsavedChangesModal;
