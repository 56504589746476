import React, { useLayoutEffect } from 'react';
import { Location, RouteComponentProps, WindowLocation } from '@reach/router';

import { ErrorName } from 'utils/errors';
import useHandleAsyncError from 'hooks/useHandleAsyncError';

interface NestedRouteProps extends RouteComponentProps {
  children: React.ReactNode;
}

export const NestedRoute = ({ children }: NestedRouteProps) => {
  return <>{children}</>;
};

export const ProtectedRoute = ({
  canAccess,
  children,
  ...props
}: { canAccess: boolean } & NestedRouteProps) => {
  const error = canAccess ? undefined : ErrorName.PermissionError;
  useHandleAsyncError(error);

  return <NestedRoute {...props}>{children}</NestedRoute>;
};

const ScrollResetter = ({ location }: { location: WindowLocation }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

// We want to reset scroll position while preserving the default
// behavior of Reach Router in which it manages focus when navigating
// between pages
// https://stackoverflow.com/a/56996986
export const ResetScrollOnNavigate = () => (
  <Location>
    {({ location }) => <ScrollResetter location={location} />}
  </Location>
);
