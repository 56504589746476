import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import Tooltip from 'components/atoms/tooltip/Tooltip';
import { ReactComponent as LockIcon } from 'assets/icons/lock-line.svg';

const InaccessibleContentIndicator = ({
  className,
}: {
  className?: string;
}) => {
  const { formatMessage: f } = useIntl();

  return (
    <Tooltip text={f({ id: 'permissions.noAccess' })}>
      <LockIcon
        height="24"
        className={classNames('fill-current inline', className)}
        data-testid="no-access-icon"
      />
    </Tooltip>
  );
};

export default InaccessibleContentIndicator;
