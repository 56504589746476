import { useIntl, FormatDateOptions } from 'react-intl';

const DEFAULT_DATE_FORMAT_OPTIONS: FormatDateOptions = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
};

const useFormatDate = () => {
  const { formatDate: f } = useIntl();

  return (value: string | number | Date, options?: FormatDateOptions) =>
    f(value, { ...DEFAULT_DATE_FORMAT_OPTIONS, ...options });
};

export default useFormatDate;
