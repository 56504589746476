import React from 'react';
import { useIntl } from 'react-intl';

import Pill from 'components/atoms/Pill';

const AssessmentCoursePill = () => {
  const { formatMessage: f } = useIntl();

  return (
    <Pill
      type={'success'}
      disabled={true}
      className={
        'w-100 text-purple-400 bg-purple-200 inline-block select-none mt-8'
      }
      data-testid={'assessment-course-pill'}
    >
      {f({
        id: `courses.assessment.label`,
      })}
    </Pill>
  );
};

export default AssessmentCoursePill;
