import { schema } from 'normalizr';

export interface Document {
  id: string;
  description: string;
  url: string;
}

export const documentSchema = new schema.Entity('documents');

export interface LinkAttachment {
  id: string;
  name: string;
  url: string;
}

export const linkAttachmentSchema = new schema.Entity('link_attachments');
