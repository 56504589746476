import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

import { ReactComponent as ClearIcon } from 'assets/icons/close.svg';

import './Banner.scss';

interface Props {
  children: React.ReactNode;
  isVisible?: boolean;
  dismissible?: boolean;
  className?: string;
}

const Banner = ({
  children,
  isVisible = false,
  dismissible = false,
  className = 'px-32',
}: Props) => {
  const [isOpen, setIsOpen] = useState(isVisible);

  useEffect(() => {
    if (isVisible) {
      window.scrollTo(0, 0);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [setIsOpen, isVisible]);

  // scroll to top on "mount"
  useEffect(() => window.scrollTo({ top: 0 }), []);

  return (
    <Transition
      in={isOpen}
      timeout={200 /* aligns with the value defined in Banner.scss */}
      mountOnEnter
      unmountOnExit
    >
      {(status: string) => (
        <div className={`Banner bg-success ${status}`} data-testid="banner">
          <div
            className={classNames(
              'Content text-white flex justify-between items-center',
              className,
            )}
          >
            <div className="mx-auto font-bold">{children}</div>

            {dismissible && (
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="focus:outline-none"
              >
                <ClearIcon height="24" width="24" className="fill-current" />
              </button>
            )}
          </div>
        </div>
      )}
    </Transition>
  );
};

export default Banner;
