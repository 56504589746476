import { difference, union, omit, mergeWith, isArray, uniq } from 'lodash-es';
import { createReducer, createAction, combineReducers } from '@reduxjs/toolkit';
import { NormalizedSchema } from 'normalizr';

import { Bundle } from 'models/bundle';
import { Topic } from 'models/topic';
import { Chapter } from 'models/chapter';
import { NormalizedCourse } from 'models/course';
import { CourseSet } from 'models/courseSet';
import { NormalizedOrganization } from 'models/organization';
import { Lesson } from 'models/lesson';
import { CourseIcon } from 'models/courseIcon';
import { Question } from 'models/question';
import { Passage } from 'models/passage';
import { User } from 'models/user';
import { VideoPresenter } from 'models/videoPresenter';
import { LinkAttachment } from 'models/document';
import { Subject } from 'models/subject';
import { CourseTag } from 'models/courseTag';

interface ById<T> {
  [id: string]: T;
}

interface EntityState<T> {
  byId: ById<T>;
  ids: string[];
}

interface Entities {
  authors: EntityState<User>;
  bundles: EntityState<Bundle<string>>;
  chapters: EntityState<Chapter>;
  courseIcons: EntityState<CourseIcon>;
  courses: EntityState<NormalizedCourse>;
  courseSets: EntityState<CourseSet>;
  courseTags: EntityState<CourseTag>;
  documents: EntityState<Document>;
  link_attachments: EntityState<LinkAttachment>;
  lessons: EntityState<Lesson<string>>;
  organizations: EntityState<NormalizedOrganization>;
  subjects: EntityState<Subject>;
  topics: EntityState<Topic<string>>;
  questions: EntityState<Question>;
  passages: EntityState<Passage>;
  videoPresenters: EntityState<VideoPresenter>;
  users: EntityState<User>;
}

const createInitialState = <T>(): EntityState<T> => ({
  byId: {},
  ids: [],
});

interface E {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [entityName: string]: any;
}
interface R {
  [entityName: string]: string[];
}
type Payload = NormalizedSchema<E, R> & {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  options?: any;
};

export const addRecords = createAction<Payload>('addRecords');
export const deleteRecords = createAction<Payload>('deleteRecords');

export const createEntitiesReducer = <T>(entityName: keyof Entities) =>
  createReducer(createInitialState<T>(), {
    [addRecords.type]: (state, { payload }) => {
      const { entities, options } = payload;

      const entityNames = Object.keys(entities);

      if (!entityNames.includes(entityName)) {
        return;
      }

      state.byId = mergeWith(
        state.byId,
        entities[entityName],
        (objValue, sourceValue, key) => {
          if (!isArray(objValue) || !isArray(sourceValue)) {
            // let merge handle all properties that are not arrays otherwise
            // merge will treat the array as an object and merge it. Doing this
            // will lead to the following problem:
            //
            // merge(['a', 'b'], ['b']) == ['b', 'b']
            //
            // the output above is the correct behavior of merge for arrays but
            // it's not what we want. Above is equivalent to:
            //
            // merge({ 0: 'a', 1: 'b' }, { 0: 'a' }) == { 0: 'b', 1: 'b' } == ['b', 'b']
            return;
          }

          if (
            options &&
            isArray(options.concatKeys) &&
            options.concatKeys.includes(key)
          ) {
            // if property name is passed in as part of options.concatKeys then
            // just concat both arrays and make sure its elements are unique
            return uniq([...objValue, ...sourceValue]);
          } else {
            // otherwise, just replace the existing array with the new one
            return sourceValue;
          }
        },
      );

      const ids = Object.keys(entities[entityName]);
      state.ids = union(state.ids, ids);
    },
    [deleteRecords.type]: (state, { payload }) => {
      const entityNames = Object.keys(payload.entities);

      if (!entityNames.includes(entityName)) {
        return;
      }

      state.byId = omit(state.byId, Object.keys(payload.entities[entityName]));

      const ids = Object.keys(payload.entities[entityName]);
      state.ids = difference(state.ids, ids);
    },
  });

export default combineReducers({
  authors: createEntitiesReducer<User>('authors'),
  bundles: createEntitiesReducer<Bundle<string>>('bundles'),
  chapters: createEntitiesReducer<Chapter>('chapters'),
  courseIcons: createEntitiesReducer<CourseIcon>('courseIcons'),
  courses: createEntitiesReducer<NormalizedCourse>('courses'),
  courseSets: createEntitiesReducer<CourseSet>('courseSets'),
  courseTags: createEntitiesReducer<CourseTag>('courseTags'),
  documents: createEntitiesReducer<Document>('documents'),
  link_attachments: createEntitiesReducer<LinkAttachment>('link_attachments'),
  lessons: createEntitiesReducer<Lesson<string>>('lessons'),
  organizations: createEntitiesReducer<NormalizedOrganization>('organizations'),
  subjects: createEntitiesReducer<Subject>('subjects'),
  topics: createEntitiesReducer<Topic<string, string, string>>('topics'),
  questions: createEntitiesReducer<Question>('questions'),
  passages: createEntitiesReducer<Passage>('passages'),
  videoPresenters: createEntitiesReducer<VideoPresenter>('videoPresenters'),
  users: createEntitiesReducer<User>('users'),
});
