import React from 'react';
import { RouteComponentProps } from '@reach/router';

import useHandleAsyncError from 'hooks/useHandleAsyncError';
import { ErrorName } from 'utils/errors';

export const NotFound = (_: RouteComponentProps) => {
  useHandleAsyncError(ErrorName.NotFound);
  return <div />;
};
