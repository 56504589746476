import { useCallback, useEffect, useState } from 'react';
import Yup, { ValidationError } from 'yup';
import { isEqual } from 'lodash-es';
// Import a functional version of set in lodash/fp,
// which will not mutate the object it is given
import set from 'lodash/fp/set';

interface Props {
  defaultValues?: Record<string, unknown>;
  validationSchema: Yup.ObjectSchema;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preprocessValues?: (values: Record<string, any>) => void;
  onChange?: () => void;
  willBlockOnValidation?: boolean;
}

export interface FieldValue {
  // TODO: Find a way to make InferType work against a dynamic schema
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const useForm = ({
  defaultValues = {},
  validationSchema,
  preprocessValues,
  onChange,
  willBlockOnValidation = true,
}: Props) => {
  const [defaultFormValues, setDefaultFormValues] = useState(defaultValues);
  const [formValues, setFormValues] = useState<FieldValue>(defaultFormValues);
  const [errors, setErrors] = useState<FieldValue>({});

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const validate = useCallback(() => {
    if (
      JSON.stringify(defaultFormValues) ===
      JSON.stringify(
        preprocessValues ? preprocessValues(formValues) : formValues,
      )
    ) {
      setIsFormDirty(false);
    } else {
      setIsFormDirty(true);
    }

    try {
      validationSchema.validateSync(formValues, {
        abortEarly: false,
      });

      setErrors({});
    } catch (_errors) {
      const errorsObject = _errors.inner.reduce(
        (
          object: { [key: string]: Record<string, unknown> },
          error: ValidationError,
        ) => {
          return { ...object, [error.path]: error };
        },
        {},
      );

      setErrors(errorsObject);
    }
  }, [formValues, validationSchema, defaultFormValues, preprocessValues]);

  useEffect(() => {
    validate();
  }, [validate]);

  useEffect(() => {
    setIsFormValid(!Object.keys(errors).length);
  }, [errors]);

  useEffect(() => {
    if (!isEqual(defaultFormValues, defaultValues)) {
      setDefaultFormValues(defaultValues);
      setFormValues(defaultValues);
    }
  }, [defaultFormValues, defaultValues]);

  const handleChange = (e: React.SyntheticEvent) => {
    const input = e.target as HTMLInputElement;
    const fieldValue = input.files ? input.files[0] : input.value;

    setFormValues({
      ...formValues,
      [input.name]: fieldValue,
    });

    if (onChange) onChange();
  };

  const setFormValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (name: string, value: any) => {
      setFormValues(prevState => set(name, value, prevState));
      if (onChange) onChange();
    },
    [onChange],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (onSubmit?: (values: any) => void) => (
    e?: React.SyntheticEvent,
  ) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (!isFormValid && willBlockOnValidation) {
      return;
    } else if (onSubmit) {
      onSubmit(formValues);
    }
  };

  return {
    errors,
    formValues,
    handleChange,
    handleSubmit,
    isFormValid,
    setFormValue,
    setFormValues,
    isFormDirty,
  };
};

export default useForm;
