import { schema } from 'normalizr';

import { Topic, topicSchema } from 'models/topic';

export interface Bundle<T = Topic> {
  course_id: string;
  course_name: string;
  school_assessment_course: boolean;
  guid: string;
  id: string;
  name: string;
  description: string;
  published_at: string;
  topics_count: number;
  topics: T[];
  errors: string[];
  author: boolean;
  organization_id: string;
}

export const bundleSchema = new schema.Entity(
  'bundles',
  {
    topics: [topicSchema],
  },
  {
    processStrategy: bundle => ({
      ...bundle,

      // Make sure bundle always have a topics property
      ...(bundle.topics ? {} : { topics: [] }),
    }),
  },
);
