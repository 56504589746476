export enum ErrorName {
  PermissionError = 'permissionError',
  NotFound = 'notFound',
  ServerError = 'serverError',
  UnprocessableEntity = 'unprocessableEntity',
  UnknownError = 'unknownError',
}

export const getErrorName = (statusCode?: number) => {
  if (statusCode === 403) {
    return ErrorName.PermissionError;
  } else if (statusCode === 404) {
    return ErrorName.NotFound;
  } else if (statusCode === 422) {
    return ErrorName.UnprocessableEntity;
  } else if (statusCode && 500 <= statusCode && statusCode < 600) {
    return ErrorName.ServerError;
  } else {
    return ErrorName.UnknownError;
  }
};
