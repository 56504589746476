import { createSelector } from 'reselect';

import { RootState } from 'app/rootReducer';
import { getUserSlice } from 'selectors/entities';
import { getOrganizations } from 'selectors/organizations';

export const getUser = createSelector(getUserSlice, ({ user }) => user);

export const isFetchingUser = createSelector(
  getUserSlice,
  ({ loading }) => loading,
);

export const fetchUserFailed = createSelector(
  getUserSlice,
  ({ error }) => !!error,
);

export const getIsAutoSaveEnabled = createSelector(
  getUserSlice,
  getOrganizations,
  (state: RootState) => state.organizations,
  ({ user }, organizations, { selectedId }) => {
    const isUserNonQuipper = !user?.email?.endsWith('@quipper.com');
    const isOrgAutosaveEnabled = !!organizations.find(o => o.id === selectedId)
      ?.enable_autosave;
    const isAutosaveActive = !!user?.use_autosave;

    return isUserNonQuipper
      ? isOrgAutosaveEnabled && isAutosaveActive
      : isAutosaveActive;
  },
);
