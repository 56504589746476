import { schema } from 'normalizr';

import { Section } from 'models/section';

export interface Passage {
  id: string;
  name: string;
  text: string;
  topic_id: string;
  last_saved_at?: string;
  published_date: string | null;
  sections: Section[];
  content_html?: string;
  licensed: boolean;
  author: boolean;

  // The result of squashing sections. This properties are populated in passage
  // selector
  content?: string;
  errors: string[];
}

export const passageSchema = new schema.Entity(
  'passages',
  {},
  {
    processStrategy: passage => {
      const section = passage.sections && passage.sections[0];

      return { ...passage, content_html: section && section.content_html };
    },
  },
);
