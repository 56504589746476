import { createSelector } from 'reselect';

import { RootState } from 'app/rootReducer';
import { squashSections } from 'utils/content';

export const getPassageEntitySlice = (state: RootState) =>
  state.entities.passages;

export const getPassage = (id: string) =>
  createSelector(getPassageEntitySlice, ({ byId: passages }) => {
    const passage = passages[id];

    if (!passage) {
      return;
    }

    return { ...passage, content: squashSections(passage.sections) };
  });

export const getPassageLastSavedAt = createSelector(
  getPassageEntitySlice,
  (_: RootState, id: string) => id,
  ({ byId: passages }, id) => {
    const passage = passages[id];

    if (!passage) {
      return;
    }

    return passage.last_saved_at;
  },
);
