import React from 'react';
import { useIntl } from 'react-intl';

import Modal from 'components/organisms/Modal';
import { Button, LinkButton } from 'components/atoms/Button';
import config from 'appConfig';
import { getLocale } from 'utils/i18n';

const ForgotPasswordModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { formatMessage: f } = useIntl();

  return (
    <Modal isOpen={isOpen} title={f({ id: 'forgotPassword.modal.title' })}>
      <div className="flex flex-grow">
        <p className="m-auto">{f({ id: 'forgotPassword.modal.message' })}</p>
      </div>
      <div className="flex justify-center mb-16">
        <Button secondary size="small" className="mr-8" onClick={onClose}>
          {f({ id: 'common.cancel' })}
        </Button>
        <LinkButton
          onClick={onClose}
          to={`${config.REACT_APP_QLINK_URL}/${getLocale()}/forgot_password`}
          target="_blank"
          rel="noreferrer noopener"
          size="small"
        >
          {f({ id: 'forgotPassword.modal.button' })}
        </LinkButton>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
