interface Configuration {
  REACT_APP_SITE_ENV: string;
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_QLINK_URL: string;
  REACT_APP_QLEARN_URL: string;
  REACT_APP_MEIKO_QCREATE_URL: string;
  REACT_APP_VERSION: string;
  REACT_APP_BUILD_VERSION: string;
}

const config: Configuration = {
  ...{
    REACT_APP_SITE_ENV: '',
    REACT_APP_SENTRY_DSN: '',
    REACT_APP_QLINK_URL: '',
    REACT_APP_QLEARN_URL: '',
    REACT_APP_MEIKO_QCREATE_URL: '',
    REACT_APP_VERSION: '',
    REACT_APP_BUILD_VERSION: '',
  },
  ...process.env,
};

export default config;
