import React, { ErrorInfo } from 'react';
import {
  withScope as sentryWithScope,
  Scope,
  captureException,
} from '@sentry/browser';

import PageTemplate from 'components/templates/PageTemplate';
import { ErrorName } from 'utils/errors';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: ErrorName;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    const catchError = Object.values(ErrorName).includes(
      (error as unknown) as ErrorName,
    );
    return {
      hasError: true,
      error: (catchError && error) || ErrorName.UnknownError,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch and send errors to Sentry
    // However, even if the error is caught,
    // the stack trace and error will still be shown in the console for DEV env
    // This is a known issue with reference -> https://github.com/facebook/create-react-app/issues/6530
    sentryWithScope((scope: Scope) => {
      scope.setExtras({ ...errorInfo });
      captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return <PageTemplate errorName={this.state.error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
