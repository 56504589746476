import React from 'react';

import useBeforeUnload from 'hooks/useBeforeUnload';

type Props = {
  hasChanges: boolean;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const UnsavedChangesContext = React.createContext<Props | undefined>(undefined);

const UnsavedChangesProvider = (props: { children: React.ReactNode }) => {
  const [hasChanges, setHasChanges] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <UnsavedChangesContext.Provider
      value={{ hasChanges, setHasChanges, isModalOpen, setIsModalOpen }}
      {...props}
    />
  );
};

const useUnsavedChanges = (condition = false) => {
  useBeforeUnload({ condition });

  const context = React.useContext(UnsavedChangesContext);
  if (context === undefined) {
    throw new Error(
      `useUnsavedChanges must be used within UnsavedChangesProvider`,
    );
  }
  return context;
};

export { UnsavedChangesProvider, useUnsavedChanges };
