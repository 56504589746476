import * as React from 'react';
import classNames from 'classnames';

import Dropdown from 'components/atoms/Dropdown/Dropdown';
import { Locale, getLocale, saveLocale, languageMap } from 'utils/i18n';
import MenuItem from 'components/atoms/Dropdown/MenuItem';
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg';

const LanguageSwitcher = () => {
  const [locale, setLocale] = React.useState(getLocale());
  const [isOpen, setIsOpen] = React.useState(false);

  const menuItems = Object.entries(languageMap).map(([localeCode, name]) => (
    <MenuItem key={localeCode}>{name}</MenuItem>
  ));

  const handleSelect = (value: string) => {
    const newLocale = value as Locale;

    setLocale(newLocale);
    saveLocale(newLocale);
  };

  return (
    <Dropdown
      onSelect={handleSelect}
      menuItems={menuItems}
      className="max-w-200"
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="focus:outline-none text-12 h-16"
      >
        {languageMap[locale]}
        <DropdownIcon
          className={classNames('ml-10 fill-current inline w-16', {
            'rotate-180': isOpen,
          })}
        />
      </button>
    </Dropdown>
  );
};

export default LanguageSwitcher;
