import React, { useEffect } from 'react';

import { fetchCourseOutline as fetchCourseOutlineAction } from 'slices/courses';
import { useDeferredAsync } from 'hooks';
import { QuipperSchoolCourseMeta } from 'models/course';
import { Subject } from 'models/subject';
import { CourseTag } from 'models/courseTag';

export type Content = { id: string; name: string; accessible: boolean };
export type Topic = Content;
export type Bundle = Content & { topics: Content[] };
export type Course = Content & {
  bundles: Bundle[];
  meta: QuipperSchoolCourseMeta;
  subjects?: Subject[];
  course_tags?: CourseTag[];
  level?: string[];
  grade_levels?: string[];
  school_assessment_course: boolean;
};

type Action =
  | { type: 'open' | 'close'; id: string }
  | { type: 'openAll' | 'closeAll'; ids: string[] }
  | { type: 'updateCourseId'; id: string }
  | { type: 'updateCourseData'; courseData: Course }
  | { type: 'refresh' }
  | { type: 'reset' };

type Dispatch = (action: Action) => void;
type State = {
  courseId?: string;
  courseData?: Course;
  opened: { [key: string]: boolean };
  counter: number;
};

const initialState = {
  opened: {},
  counter: 0,
};

const CourseTreeStateContext = React.createContext<State | undefined>(
  undefined,
);
const CourseTreeDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'updateCourseId': {
      const { id } = action;
      if (id === state.courseId) return state;
      return { ...initialState, courseId: id };
    }
    case 'updateCourseData': {
      const { courseData } = action;
      return { ...state, courseData };
    }
    case 'open': {
      const { id } = action;
      if (state.opened[id]) return state;
      return { ...state, opened: { ...state.opened, [id]: true } };
    }
    case 'close': {
      const { id } = action;
      if (!state.opened[id]) return state;
      return { ...state, opened: { ...state.opened, [id]: false } };
    }
    case 'openAll': {
      const { ids } = action;
      const updatedIds = ids.reduce(
        (obj, _id) => ({ ...obj, [_id]: true }),
        {},
      );
      return { ...state, opened: { ...state.opened, ...updatedIds } };
    }
    case 'closeAll': {
      const { ids } = action;
      const updatedIds = ids.reduce(
        (obj, _id) => ({ ...obj, [_id]: false }),
        {},
      );
      return { ...state, opened: { ...state.opened, ...updatedIds } };
    }
    case 'reset': {
      return initialState;
    }
    case 'refresh': {
      return { ...state, counter: state.counter + 1 };
    }
  }
};

const CourseTreeProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <CourseTreeStateContext.Provider value={state}>
      <CourseTreeDispatchContext.Provider value={dispatch}>
        {children}
      </CourseTreeDispatchContext.Provider>
    </CourseTreeStateContext.Provider>
  );
};

const useCourseTreeState = () => {
  const context = React.useContext(CourseTreeStateContext);
  if (context === undefined) {
    throw new Error(
      'useCourseTreeState must be used within a CourseTreeProvider',
    );
  }
  const isOpened = (id: string) => !!context.opened[id];
  return {
    isOpened,
    courseData: context.courseData,
    courseId: context.courseId,
    counter: context.counter,
  };
};

const useCourseTreeDispatch = () => {
  const context = React.useContext(CourseTreeDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useCourseTreeDispatch must be used within a CourseTreeProvider',
    );
  }
  return context;
};

const useCourseTree = (id?: string) => {
  const dispatch = useCourseTreeDispatch();
  const { courseId, counter } = useCourseTreeState();

  const { run: fetchCourseOutline, result: newCourseData } = useDeferredAsync(
    fetchCourseOutlineAction,
    {
      id,
    },
  );

  useEffect(() => {
    if (id) {
      dispatch({ type: 'updateCourseId', id });
      fetchCourseOutline({ id });
    }
  }, [dispatch, fetchCourseOutline, id]);

  useEffect(() => {
    if (newCourseData) {
      dispatch({ type: 'updateCourseData', courseData: newCourseData });
    }
  }, [newCourseData, dispatch]);

  // Triggered by dispatching 'refresh' action to force the navigation sidebar
  // to fetch a fresh copy of the course outline after a certain action (e.g.
  // moving a topic to another bundle)
  useEffect(() => {
    if (courseId && counter > 0) {
      fetchCourseOutline({ id: courseId });
    }
  }, [counter, fetchCourseOutline, courseId]);
};

const useResetCourseTree = () => {
  const dispatch = useCourseTreeDispatch();

  useEffect(() => {
    dispatch({ type: 'reset' });
  }, [dispatch]);
};

export {
  CourseTreeProvider,
  useCourseTreeState,
  useCourseTreeDispatch,
  useCourseTree,
  useResetCourseTree,
};
