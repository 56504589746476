import { schema } from 'normalizr';

import { Organization } from 'models/organization';

type Role =
  | 'Administrator'
  | 'Publisher'
  | 'Editor'
  | 'Limited Editor'
  | 'Restricted'
  | '';

export interface User {
  id: string;
  name: string;
  email: string;
  token: string;
  profile_image_url?: string;
  administrable_organizations: Organization[];
  admin_systems: string[];
  admin?: boolean;
  restricted?: boolean;
  role: Role;
  use_autosave: boolean;
  has_multiple_organizations: boolean;
}

export const userSchema = new schema.Entity('users');
export const authorSchema = new schema.Entity('authors');
