import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { RootState } from 'app/rootReducer';
import { lessonSchema } from 'models/lesson';
import { getChapterEntitySlice } from 'selectors/chapters';

export const getLessonEntitySlice = (state: RootState) =>
  state.entities.lessons;

export const getLesson = (id: string) =>
  createSelector(
    getLessonEntitySlice,
    getChapterEntitySlice,
    ({ byId: lessons }, { byId: chapters }) =>
      denormalize(lessons[id], lessonSchema, { chapters }),
  );
