import React from 'react';
import { navigate } from '@reach/router';

import { Topic } from 'models/topic';
import { getLocale } from 'utils/i18n';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import UnsavedChangesModal from 'components/organisms/UnsavedChangesModal';
import { useModal } from 'hooks';
import { useUnsavedChanges } from 'context/UnsavedChangesContext';

const TopicNavbar = ({ topic }: { topic?: Topic }) => {
  const { hasChanges } = useUnsavedChanges();
  const { close, open, isOpen } = useModal();

  const topicName = topic ? topic.name : '';
  const bundleName = topic ? topic.bundle_name : '';

  return (
    <>
      <nav className="z-10 sticky top-0 bg-grey-plus2 text-white">
        {/* This follows the design of <Navbar /> where height is 74px hence
      the div with 4px height */}
        <div className="h-4"></div>
        <div className="flex flex-col justify-center items-center h-70">
          <span className="text-center w-50p text-12 truncate">
            {bundleName}
          </span>
          <span className="text-center w-50p text-16 font-bold truncate">
            {topicName}
          </span>
        </div>
        <Logo
          height="30"
          className="cursor-pointer fill-current inset-y-center left-32 absolute"
          onClick={() => (hasChanges ? open() : navigate(`/${getLocale()}`))}
        />
      </nav>

      <UnsavedChangesModal
        isOpen={isOpen}
        onCancel={() => navigate(`/${getLocale()}`)}
        onSave={close}
      />
    </>
  );
};

export const TopicNavbarFallback = () => {
  return (
    <nav className="z-10 sticky top-0 bg-grey-plus2 text-white">
      {/* This follows the design of <Navbar /> where height is 74px hence
      the div with 4px height */}
      <Logo
        height="30"
        className="cursor-pointer fill-current inset-y-center left-32 absolute"
        onClick={() => navigate(`/${getLocale()}`)}
      />
    </nav>
  );
};

export default TopicNavbar;
