import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import { getLocale } from 'utils/i18n';
import { LinkButton, Button } from 'components/atoms/Button';
import { ErrorName } from 'utils/errors';
import { ReactComponent as ServerErrorSVG } from 'assets/errors/500.svg';
import { ReactComponent as NotFoundSVG } from 'assets/errors/404.svg';
import { ReactComponent as PermissionErrorSVG } from 'assets/errors/403.svg';
import { getUser } from 'selectors/user';

const ErrorImage = ({ errorName }: { errorName: ErrorName }) => {
  const ICON_HEIGHT = 265;

  switch (errorName) {
    case ErrorName.PermissionError:
      return <PermissionErrorSVG height={ICON_HEIGHT} />;
    case ErrorName.NotFound:
      return <NotFoundSVG height={ICON_HEIGHT} />;
    case ErrorName.ServerError:
      return <ServerErrorSVG height={ICON_HEIGHT} />;
    default:
      return null;
  }
};

const ErrorPage = ({ errorName }: { errorName: ErrorName }) => {
  const { formatMessage: f } = useIntl();
  const user = useSelector(getUser);

  return (
    <>
      <Helmet>
        <title>{`Create | ${f({ id: 'errorTitle.' + errorName })}`}</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center h-full py-96">
        {errorName === ErrorName.UnknownError ? (
          <span className="text-20 font-bold self-center">
            {f({ id: 'errors.unknownError' })}
          </span>
        ) : (
          <>
            <ErrorImage errorName={errorName} />
            <span className="text-34 font-extrabold mt-32 mb-40">
              {f({ id: `errors.${errorName}.title` })}
            </span>
            <span className="text-20 font-bold mb-16">
              {f({ id: `errors.${errorName}.subtitle` })}
            </span>
            <span className="text-16">
              {f({ id: `errors.${errorName}.p1` })}
            </span>
            <span className="text-16">
              {f({ id: `errors.${errorName}.p2` })}
            </span>
          </>
        )}
        <div
          className={classNames('mt-32 flex justify-between', {
            'w-50p': user,
          })}
        >
          {user && (
            <LinkButton
              className="w-400 text-center mr-8"
              to={`${window.location.origin}/${getLocale()}`}
            >
              {f({ id: 'errors.backToCourseList' })}
            </LinkButton>
          )}
          {![ErrorName.PermissionError, ErrorName.NotFound].includes(
            errorName,
          ) && (
            <Button
              className={'w-400 text-center'}
              onClick={() => window.location.reload()}
            >
              {f({ id: 'common.refresh' })}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
