import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import './ToggleSwitch.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  name: string;
}

const ToggleSwitch = ({
  onChange,
  checked,
  name,
  disabled,
  readOnly,
}: Props) => {
  return (
    // prevents the AvatarMenu to be closed
    <label
      onClick={e => e.stopPropagation()}
      onKeyDown={e => {
        e.stopPropagation();
      }}
      className={classNames({ 'cursor-not-allowed': disabled })}
    >
      <input
        name={name}
        id={name}
        type="checkbox"
        data-testid="toggle-switch"
        className={classNames('track')}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
      />
      <span className={classNames('thumb')}></span>
    </label>
  );
};

export default ToggleSwitch;
