import React from 'react';
import classNames from 'classnames';

import { ReactComponent as LargeSpinner } from 'assets/icons/spinner-lg.svg';
import { ReactComponent as SmallSpinner } from 'assets/icons/spinner-sm.svg';

import './Spinner.css';

const Spinner = ({
  large,
  className,
}: {
  large?: boolean;
  className?: string;
}) => {
  const SpinnerIcon = large ? LargeSpinner : SmallSpinner;

  return (
    <SpinnerIcon
      className={classNames('Spinner m-auto fill-current', className)}
      data-testid="spinner"
    />
  );
};

export default Spinner;
