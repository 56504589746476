import { ThunkActionCreator } from 'app/store';
import { get } from 'utils/api';
import { JobStatusType } from 'models/common';

interface JobStatus {
  status: string;
  uuid: string;
  same_container: boolean;
}

export const FETCH_JOB_INTERVAL = 3000;

export const fetchJobStatus: ThunkActionCreator<JobStatus> = jobStatus => async dispatch => {
  const { status, uuid } = jobStatus;
  if (status === JobStatusType.Completed) return;
  if (status === JobStatusType.Failed) {
    throw 422;
  }

  await new Promise(resolve => setTimeout(resolve, FETCH_JOB_INTERVAL));
  const res = await get(`/jobs/${uuid}`);
  if (res.status >= 400) {
    throw res.status;
  }

  const job = await res.json();
  await dispatch(fetchJobStatus(job));
};
