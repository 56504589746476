import React from 'react';

import { useAuth } from './AuthContext';

import { User } from 'models/user';

const UserContext = React.createContext<User | null | undefined>(undefined);

const UserProvider = (props: { children: React.ReactNode }) => {
  const { user } = useAuth();
  return <UserContext.Provider value={user} {...props} />;
};

const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
};

export { UserProvider, useUser };
