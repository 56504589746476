export enum ContentType {
  Course = 'course',
  Bundle = 'bundle',
  Topic = 'topic',
  Chapter = 'chapter',
  Question = 'question',
  Passage = 'passage',
}

export enum ActionType {
  Copy = 'copy',
  Move = 'move',
}

export enum JobStatusType {
  Completed = 'completed',
  Failed = 'failed',
}
