import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Alert from 'components/atoms/Alert';

import './Modal.css';

// Hide the application from screenreaders and other assistive technologies
// while the modal is open
// See http://reactcommunity.org/react-modal/examples/set_app_element.html
if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
} else {
  ReactModal.setAppElement(document.createElement('div'));
}

const ModalTitle = ({
  title,
  overline,
  subtitle,
  rightTitle,
  onClose,
}: {
  title?: string;
  overline?: string;
  subtitle?: string;
  rightTitle?: string;
  onClose?: () => void;
}) => {
  return (
    <div className="flex flex-shrink-0 justify-between font-bold text-16 py-16 pl-24 pr-16 border-b border-grey-minus2">
      <div className="flex flex-col max-w-full">
        {overline && (
          <span className="font-bold text-12 text-grey-plus1 mb-8 truncate">
            {overline}
          </span>
        )}
        {title}
        {subtitle && (
          <span className="font-normal text-10 text-grey-plus1 truncate">
            {subtitle}
          </span>
        )}
      </div>
      {rightTitle && (
        <div className="flex justify-end self-center">
          <span className="text-grey-plus1">{rightTitle}</span>
        </div>
      )}
      {onClose && (
        <span
          onClick={onClose}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onClose();
            }
          }}
          className="text-grey cursor-pointer"
          data-testid="modal-close-button"
        >
          <CloseIcon width="20" height="20" className="inline fill-current" />
        </span>
      )}
    </div>
  );
};

const Modal = ({
  children,
  isOpen,
  title,
  overline,
  subtitle,
  rightTitle,
  onClose,
  className,
  errorMessage,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  title?: string;
  overline?: string;
  subtitle?: string;
  rightTitle?: string;
  onClose?: () => void;
  className?: string;
  errorMessage?: string;
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      bodyOpenClassName="overflow-hidden"
      overlayClassName="fixed inset-0 overflow-y-auto bg-modalOverlay"
      className="px-32 absolute min-w-screen min-h-screen max-w-full flex flex-col justify-center items-center py-40 focus:outline-none"
    >
      <div
        data-testid="modal"
        className={classNames(
          'flex flex-col min-w-568 min-h-160 bg-white shadow-modal rounded-6',
          className,
        )}
      >
        {title && (
          <ModalTitle
            title={title}
            overline={overline}
            subtitle={subtitle}
            rightTitle={rightTitle}
            onClose={onClose}
          />
        )}
        {children}
      </div>

      {errorMessage && (
        <Alert className="min-w-568 mt-24 text-center">{errorMessage}</Alert>
      )}
    </ReactModal>
  );
};

export default Modal;
