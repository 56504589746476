export const getRootDomain = (host = window.location.hostname) => {
  const parts = host.split('.');
  const ipv4Regex = /^(\d{1,3}\.){3,3}\d{1,3}$/;

  if (parts.length === 1) {
    return parts[0];
  }

  if (ipv4Regex.test(host)) {
    return host;
  }

  return parts.slice(parts.length - 2, parts.length).join('.');
};
