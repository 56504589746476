const sanitizeString = (s: string) =>
  s
    // Remove all non-alphanumeric characters
    .replace(/\W/g, '')
    // Remove underscores because \W treats them as alphanumeric characters
    .replace(/_/g, '')
    .toLowerCase();

export const hasMatch = (data: string, searchTerm: string) => {
  return sanitizeString(data).includes(sanitizeString(searchTerm));
};
