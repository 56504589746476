import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';

import rootReducer, { RootState } from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
});

export type Dispatch = typeof store.dispatch;
interface ActionCreator<Payload, Action> {
  (args: Payload): Action;
}

export type ThunkActionCreator<Payload = void> = ActionCreator<
  Payload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ThunkAction<Promise<any>, RootState, null, Action<string>>
>;

export default store;
