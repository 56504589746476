import React from 'react';

import Navbar from 'components/organisms/Navbar';
import { TopicNavbarFallback } from 'components/organisms/TopicNavbar';
import Footer from 'components/organisms/footer/Footer';

const AppFallback = () => {
  const hasSidebar = ['questions/', 'chapters/', 'passages/'].some(path =>
    window.location.pathname.includes(path),
  );

  const defaultPage = (
    <div className="min-h-screen flex flex-col justify-between">
      <Navbar withNavigation={false} />
      <Footer className="w-full" />
    </div>
  );

  return (
    <div className="h-screen bg-grey-minus3">
      {hasSidebar ? <TopicNavbarFallback /> : defaultPage}
    </div>
  );
};

export default AppFallback;
