import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { compact, isString } from 'lodash-es';

import { RootState } from 'app/rootReducer';
import { topicSchema } from 'models/topic';
import { getLessonEntitySlice } from 'selectors/lessons';
import { getChapterEntitySlice } from 'selectors/chapters';
import {
  getDocumentEntitySlice,
  getLinkAttachmentEntitySlice,
} from 'selectors/documents';
import { getPassageEntitySlice } from 'selectors/passages';
import { getCourseEntitySlice } from 'selectors/entities';

const getQuestionEntitySlice = (state: RootState) => state.entities.questions;
export const getTopicEntitySlice = (state: RootState) => state.entities.topics;
const getTopicsSlice = (state: RootState) => state.topics;

export const getTopic = (id: string) =>
  createSelector(
    getTopicEntitySlice,
    getLessonEntitySlice,
    getChapterEntitySlice,
    getDocumentEntitySlice,
    getLinkAttachmentEntitySlice,
    getQuestionEntitySlice,
    getPassageEntitySlice,
    (
      { byId: topics },
      { byId: lessons },
      { byId: chapters },
      { byId: documents },
      { byId: link_attachments },
      { byId: questions },
      { byId: passages },
    ) => {
      const topic = denormalize(topics[id], topicSchema, {
        lessons,
        chapters,
        documents,
        link_attachments,
        questions,
        passages,
      });

      if (topic) {
        return {
          ...topic,
          lesson: {
            // New topics have an undefined lesson property
            ...(topic.lesson
              ? {
                  ...topic.lesson,

                  // Deleted documents will be undefined so filter those out
                  documents: compact(topic.lesson.documents),
                  // Deleted documents will be undefined so filter those out
                  link_attachments: compact(topic.lesson.link_attachments),
                  // Deleted chapters will be undefined so filter those out
                  chapters: compact(topic.lesson.chapters),
                }
              : {}),
          },
          // Deleted questions will be undefined so filter those out
          questions: compact(topic.questions),
          // Deleted passages will be undefined so filter those out
          passages: compact(topic.passages),
        };
      }
    },
  );

export const getTopicQuestions = createSelector(
  getTopicEntitySlice,
  getQuestionEntitySlice,
  (_: RootState, topicId: string) => topicId,
  ({ byId: topics }, { byId: questions }, topicId) => {
    const topic = topics[topicId];
    if (!topic) return [];

    const ids = topic.questions;

    // deleted questions will be undefined so call compact
    return compact(ids?.map(id => questions[id]));
  },
);

export const getTopicGrades = createSelector(
  getCourseEntitySlice,
  getTopicEntitySlice,
  getTopicsSlice,
  ({ byId: courses }, { byId: topics }, { selectedTopicId }) => {
    if (!selectedTopicId || !topics[selectedTopicId]) return [];

    let grades;
    const topic = topics[selectedTopicId];
    const course = courses[topic.course_id];

    if (course) {
      // To get updated values without refetching topic, get levels from course if present
      grades = course.meta.level;
    } else {
      grades = topic.grades;
    }

    // Handle edge case where empty course meta levels are empty strings
    // https://github.com/quipper/quipper/issues/27176
    return !grades || isString(grades) ? [] : grades;
  },
);
