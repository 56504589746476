import { schema } from 'normalizr';

import { chapterSchema, Chapter } from './chapter';
import {
  documentSchema,
  linkAttachmentSchema,
  Document,
  LinkAttachment,
} from './document';

export interface Lesson<T = Chapter, V = Document, D = LinkAttachment> {
  id: string;
  chapters?: T[];
  documents: V[];
  link_attachments: D[];
  file_conversion_in_progress: boolean;
}

export const lessonSchema = new schema.Entity(
  'lessons',
  {
    chapters: [chapterSchema],
    documents: [documentSchema],
    link_attachments: [linkAttachmentSchema],
  },
  {
    processStrategy: lesson => ({
      ...lesson,

      // Make sure lesson always have a chapters property
      ...(lesson.chapters ? {} : { chapters: [] }),
    }),
  },
);
