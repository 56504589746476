import React from 'react';
import RcTooltip from 'rc-tooltip';

// use lib default styles and override some things
import 'rc-tooltip/assets/bootstrap_white.css';
import './Tooltip.css';

interface Props {
  children: React.ReactElement;
  text: string;
  placement?: 'top' | 'bottom' | 'left' | 'right' | 'bottomLeft';
  className?: string;
}

const Tooltip = ({ text, placement = 'top', ...props }: Props) => {
  return (
    <RcTooltip
      {...props}
      placement={placement}
      trigger={['hover']}
      overlay={<span>{text}</span>}
      overlayClassName="Tooltip"
      mouseLeaveDelay={0}
    />
  );
};

export default Tooltip;
