import React from 'react';

import Tooltip from 'components/atoms/tooltip/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as InfoIconActive } from 'assets/icons/info-active.svg';

interface Props {
  name?: string;
  text: string;
  placement?: 'top' | 'bottom' | 'left' | 'right' | 'bottomLeft';
}

const Info = ({ text, placement = 'top', name }: Props) => {
  return (
    <Tooltip text={text} placement={placement} className="cursor-pointer">
      <span className="group relative ml-8" data-testid={name}>
        <InfoIconActive className="hidden group-hover:inline h-16 text-grey fill-current" />
        <InfoIcon className="group-hover:invisible h-16 text-grey fill-current inline" />
      </span>
    </Tooltip>
  );
};

export default Info;
