import React from 'react';
import { useIntl } from 'react-intl';

import Modal from 'components/organisms/Modal';
import { Button, LinkTextButton } from 'components/atoms/Button';

const SUPPORTED_BROWSERS = [
  { name: 'chrome', link: 'https://www.google.com/chrome/' },
  { name: 'safari', link: 'https://support.apple.com/HT204416' },
  { name: 'firefox', link: 'https://www.mozilla.org/' },
];

export const SupportedBrowsersModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { formatMessage: f } = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      className="top-20p"
      title={f({ id: 'browsersModal.title' })}
    >
      <div className="flex flex-col items-center mt-32 mb-16">
        <p>{f({ id: 'browsersModal.description' })}</p>

        <ul className="flex flex-row">
          {SUPPORTED_BROWSERS.map(({ name, link }) => (
            <li key={name} className="text-center mx-36 my-32">
              <img
                src={require(`assets/browserLogos/${name}.svg`)}
                className="w-80 mb-8 mx-auto"
                alt={`${name}-logo`}
              />
              <LinkTextButton to={link}>
                {f({ id: `browsersModal.browsers.${name}` })}
              </LinkTextButton>
            </li>
          ))}
        </ul>

        <Button secondary onClick={onClose}>
          {f({ id: 'browsersModal.button' })}
        </Button>
      </div>
    </Modal>
  );
};
