import { isString } from 'lodash-es';

import { compareLocale } from 'utils/i18n';

export const sort = (strings: string[], reverse = false) => {
  if (reverse) {
    return strings.sort((a, b) => compareLocale(b, a));
  } else {
    return strings.sort((a, b) => compareLocale(a, b));
  }
};

export const reverseSort = (strings: string[]) => sort(strings, true);

export const sortBasicGradeLevels = (levels: string | string[] = []) => {
  // Handle edge case where empty course meta levels are empty strings
  // https://github.com/quipper/quipper/issues/27176
  if (isString(levels)) {
    return [];
  }

  return reverseSort(levels);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortBy = (list: Array<any>, key: string) => {
  const filteredList = list.filter(l => l[key]);
  return filteredList.sort((a, b) => compareLocale(a[key], b[key]));
};
