import { camelCase } from 'lodash-es';

import { Feature, Organization } from 'models/organization';

export const getRole = (organization: Organization) => {
  if (!organization) {
    return 'noRole';
  }

  const manageByRoles = organization.use_teams_in_qcreate;

  if (organization.admin) {
    return 'administrator';
  } else if (organization.restricted) {
    return 'restricted';
  } else if (!manageByRoles) {
    return 'noTeams';
  } else if (organization?.role) {
    return camelCase(organization?.role);
  } else {
    return 'noRole';
  }
};

export const hasFeatures = (feature: Feature, organization?: Organization) => {
  return !!organization?.features.includes(feature);
};
