import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from '@reach/router';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AvatarMenu from 'components/molecules/AvatarMenu';
import OrganizationSwitcher from 'components/molecules/OrganizationSwitcher';
import NavbarLink from 'components/atoms/NavbarLink';
import { getLocale } from 'utils/i18n';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as CoursesIcon } from 'assets/icons/courses.svg';
import { ReactComponent as FilledCoursesIcon } from 'assets/icons/courses-filled.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/team.svg';
import { ReactComponent as FilledTeamIcon } from 'assets/icons/team-filled.svg';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import { ReactComponent as FilledTagIcon } from 'assets/icons/tag-filled.svg';
import {
  getIsUserOrgAdmin,
  getSelectedOrganization,
} from 'selectors/organizations';

interface Props {
  withOrgSwitcher?: boolean;
  withNavigation: boolean;
  className?: string;
}

const Navbar = ({
  withOrgSwitcher = false,
  withNavigation = true,
  className,
}: Props) => {
  const { formatMessage: f } = useIntl();
  const isUserOrgAdmin = useSelector(getIsUserOrgAdmin);
  const organization = useSelector(getSelectedOrganization);
  const isVerifiedOrganization = organization?.verified;

  return (
    <nav className={classNames('z-10 sticky top-0 bg-white shadow', className)}>
      <div className="bg-grey-plus2 h-4"></div>
      <div className="px-32 h-70 flex justify-between">
        <div className="flex items-center">
          <Link to={`/${getLocale()}`} className="text-primary cursor-pointer">
            <Logo
              height="30"
              className="fill-current"
              data-testid="quipper-logo"
            />
          </Link>
          {withOrgSwitcher && <OrganizationSwitcher className="ml-48" />}
        </div>

        {withNavigation && (
          <div className="flex items-center">
            {isVerifiedOrganization && (
              <>
                <NavbarLink
                  Icon={CoursesIcon}
                  ActiveIcon={FilledCoursesIcon}
                  to={`/${getLocale()}`}
                  className="w-72 mr-4"
                  // icon status will always be active except in /teams/* or /tags/*
                  matcher={(pathname: string) =>
                    !pathname.startsWith(`/${getLocale()}/teams`) &&
                    !pathname.startsWith(`/${getLocale()}/tags`)
                  }
                  data-testid="navbar-courses"
                >
                  {f({ id: 'navbar.courses' })}
                </NavbarLink>

                <NavbarLink
                  Icon={TeamIcon}
                  ActiveIcon={FilledTeamIcon}
                  to={`/${getLocale()}/teams`}
                  className="w-72 mr-4"
                  data-testid="navbar-team"
                >
                  {f({ id: 'navbar.team' })}
                </NavbarLink>

                {isUserOrgAdmin && (
                  <NavbarLink
                    Icon={TagIcon}
                    ActiveIcon={FilledTagIcon}
                    to={`/${getLocale()}/tags`}
                    className="w-72"
                    matcher={(pathname: string) =>
                      pathname.startsWith(`/${getLocale()}/tags`)
                    }
                  >
                    {f({ id: 'navbar.tag' })}
                  </NavbarLink>
                )}
              </>
            )}
            <AvatarMenu className="ml-16" showLinks={isVerifiedOrganization} />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
