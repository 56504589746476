import { navigate } from '@reach/router';
import Cookies from 'js-cookie';

import { getLocale } from 'utils/i18n';
import { getToken, SHARED_COOKIE_OPTIONS } from 'utils/cookies';
import { Topic } from 'models/topic';
import config from 'appConfig';

export const initialize = () => {
  Cookies.set('language', getLocale(), SHARED_COOKIE_OPTIONS);
};

export const navigateTo = (path: string, state?: Record<string, unknown>) =>
  navigate(`/${getLocale()}/${path}`, { state: { ...state } });

export const getPreviewUrl = (
  topic: Topic,
  source: 'questions' | 'chapters',
) => {
  const authToken = encodeURIComponent(getToken() || '');

  const lessonPreviewUrl = `${
    config.REACT_APP_QLEARN_URL
  }/preview/topics/${topic && topic.id}/lessons/${authToken}`;
  const quizPreviewUrl = `${
    config.REACT_APP_QLEARN_URL
  }/preview/topics/${topic && topic.id}/questions/1/${authToken}`;

  if (source == 'questions') {
    return quizPreviewUrl;
  } else if (source == 'chapters') {
    return topic && topic.chapters_count > 0
      ? lessonPreviewUrl
      : quizPreviewUrl;
  } else {
    return '';
  }
};
