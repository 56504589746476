import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { MenuItemProps } from 'rc-menu/lib/MenuItem';
import { useSelector } from 'react-redux';

import { RootState } from 'app/rootReducer';
import MenuItem from 'components/atoms/Dropdown/MenuItem';
import InfoIcon from 'components/atoms/Info';
import { getIsAutoSaveEnabled } from 'selectors/user';
import ToggleSwitch from 'components/atoms/ToggleSwitch';
import { updateUserAutoSaveSetting as updateOrganizationTeamAction } from 'slices/user';
import { useDeferredAsync } from 'hooks';
import { getSelectedOrganization } from 'selectors/organizations';

const AutoSaveToggle = ({ ...props }: MenuItemProps) => {
  const { formatMessage: f } = useIntl();
  const isAutoSaveEnabled = useSelector((state: RootState) => {
    return getIsAutoSaveEnabled(state);
  });
  const isAutoSaveFeatureActive = useSelector(getSelectedOrganization)
    ?.enable_autosave;
  const [checked, setChecked] = useState(isAutoSaveEnabled);
  const {
    failed: updateFailed,
    run: updateUserAutoSaveSetting,
    pending: isUpdating,
  } = useDeferredAsync(updateOrganizationTeamAction);

  const handleOnChange = () => {
    updateUserAutoSaveSetting({ useAutoSave: !checked });
    setChecked(!checked);
  };

  useEffect(() => {
    if (isAutoSaveEnabled !== undefined || updateFailed) {
      setChecked(isAutoSaveEnabled);
    }
  }, [isAutoSaveEnabled, updateFailed]);

  return (
    <MenuItem className="flex justify-between items-center" {...props}>
      <div>
        {f({ id: 'avatarMenu.autoSave' })}
        <InfoIcon
          name="autosave-info"
          text={f({ id: 'avatarMenu.autoSaveInfo' })}
          placement="bottom"
        />
      </div>
      <ToggleSwitch
        name="manageAutoSaveSetting"
        checked={checked}
        onChange={handleOnChange}
        disabled={isUpdating || !isAutoSaveFeatureActive}
      />
    </MenuItem>
  );
};

export default AutoSaveToggle;
