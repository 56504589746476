import classNames from 'classnames';
import React, {
  ButtonHTMLAttributes,
  ReactNode,
  AnchorHTMLAttributes,
} from 'react';
import { Link, LinkProps } from '@reach/router';

import Spinner from 'components/atoms/Spinner';

import './Button.css';

type Size = 'small' | 'medium' | 'large';
type Color = 'danger' | 'success';

type ColorClassMap = {
  [color in Color]: { bg: string; 'hover:bg': string };
};

// Create a color–class map instead of dynamically generating class
// names so that the CSS can remain purgeable
const colorClassMap: ColorClassMap = {
  success: {
    bg: 'bg-success',
    'hover:bg': 'hover:bg-success-hover',
  },
  danger: {
    bg: 'bg-danger',
    'hover:bg': 'hover:bg-danger-hover',
  },
};

interface Props {
  size?: Size;
  secondary?: boolean;
  tertiary?: boolean;
  outline?: boolean;
  isLoading?: boolean;
  color?: Color;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export type ButtonProps = Props & ButtonHTMLAttributes<HTMLButtonElement>;

const defaultClasses = `
  Button
  px-16
  rounded
  font-bold
  focus:outline-none
`;

const getClasses = ({
  size = 'medium',
  color,
  secondary = false,
  tertiary = false,
  outline = false,
  disabled = false,
  isLoading = false,
  classOverrides,
}: Props & { disabled?: boolean; classOverrides?: string }) => {
  let typeClasses = '';

  if (secondary) {
    typeClasses =
      'border border-grey-minus1 bg-white text-grey-plus2 hover:shadow-buttonSecondary';
  } else if (tertiary) {
    typeClasses = 'text-grey-plus1 hover:text-grey-plus2 hover:bg-grey-minus2';
  } else if (outline) {
    typeClasses =
      'border border-primary-plus1 bg-white text-primary-plus1 hover:bg-grey-minus2';
  } else {
    typeClasses =
      'text-white bg-primary-plus1 shadow-button hover:bg-primary-hover hover:shadow-buttonHover';
  }

  if (color) {
    typeClasses = `
      text-white
      shadow-button hover:shadow-buttonSecondary
      ${colorClassMap[color]['bg']} ${colorClassMap[color]['hover:bg']}
    `;
  }

  if (disabled || isLoading) {
    typeClasses = tertiary
      ? 'text-grey-minus1 align-top cursor-default'
      : 'bg-grey-minus2 text-grey align-top cursor-default';
  }

  let sizeClasses = 'min-h-48 h-48 text-14 py-12';

  switch (size) {
    case 'large':
      sizeClasses = 'min-h-64 h-64 text-16';
      break;
    case 'small':
      sizeClasses = 'min-h-40 h-40 text-12 py-8';
      break;
  }

  return classNames(defaultClasses, typeClasses, sizeClasses, classOverrides);
};

interface LinkButtonProps extends Props {
  disabled?: boolean;
}

export const LinkButton = React.forwardRef(
  (
    {
      to,
      className,
      secondary,
      size,
      Icon,
      color,
      disabled,
      ...props
    }: LinkButtonProps & LinkProps<Record<string, unknown>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any,
  ) => {
    const _className = getClasses({
      secondary,
      size,
      color,
      classOverrides: className,
      disabled,
    });

    const children = (
      <>
        {props.children}
        {Icon && <Icon className="inline h-16 fill-current ml-16" />}
      </>
    );

    if (disabled) {
      return (
        <button disabled className={_className}>
          {children}
        </button>
      );
    } else if (to && to.startsWith('http')) {
      return (
        <a
          href={to}
          className={_className}
          {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}
        >
          {children}
        </a>
      );
    } else {
      return (
        <Link ref={ref} to={to} className={_className} {...props}>
          {children}
        </Link>
      );
    }
  },
);

export const Button = ({
  children,
  className,
  secondary,
  size,
  color,
  disabled,
  isLoading,
  ...props
}: ButtonProps) => {
  return (
    <button
      type="button"
      className={getClasses({
        secondary,
        size,
        disabled,
        isLoading,
        color,
        classOverrides: className,
      })}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? <Spinner className="h-16" /> : children}
    </button>
  );
};

const getTextButtonClasses = (small: boolean, disabled: boolean) =>
  classNames(
    small ? 'p-8 text-12' : 'h-40 px-12',
    'inline-flex items-center rounded font-bold focus:outline-none',
    disabled
      ? 'cursor-default text-grey-minus1'
      : 'hover:bg-grey-minus2 text-primary-plus1',
  );

export const LinkTextButton = ({
  to,
  className,
  small = false,
  children,
  ...props
}: { small?: boolean } & LinkProps<Record<string, unknown>>) => {
  if (to?.startsWith('http')) {
    return (
      <a
        href={to}
        className={classNames(getTextButtonClasses(small, false), className)}
        {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link
        to={to}
        // Links cannot be disabled. Use <TextButton /> instead
        className={classNames(getTextButtonClasses(small, false), className)}
        {...props}
        ref={null}
      >
        {children}
      </Link>
    );
  }
};

interface TextButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode;
}

interface LabelTextButtonProps extends TextButtonProps {
  labelFor: string;
}

export const TextButton = ({
  children,
  className,
  disabled,
  icon,
  small = false,
  ...props
}: (TextButtonProps | LabelTextButtonProps) & { small?: boolean }) => {
  const classes = classNames(
    getTextButtonClasses(small, !!disabled),
    className,
  );
  const _children = (
    <>
      {icon}
      {children}
    </>
  );

  // When using TextButton as a trigger to open a hidden input
  if ('labelFor' in props) {
    return (
      <label
        htmlFor={props.labelFor}
        className={classNames('inline', !disabled && 'cursor-pointer', classes)}
      >
        {_children}
      </label>
    );
  }

  return (
    <button type="button" className={classes} disabled={disabled} {...props}>
      {_children}
    </button>
  );
};

export const TertiaryButton = ({
  size,
  Icon,
  children,
  className,
  disabled,
  ...props
}: ButtonProps) => {
  return (
    <button
      type="button"
      className={classNames(
        getClasses({
          tertiary: true,
          size,
          classOverrides: className,
          disabled,
        }),
        `flex items-center`,
      )}
      disabled={disabled}
      {...props}
    >
      {Icon && (
        <Icon
          height={size === 'small' ? '20' : '24'}
          className={classNames(
            'inline fill-current',
            size === 'small' ? 'mr-8' : 'mr-16',
          )}
        />
      )}
      {children}
    </button>
  );
};

export const OutlineButton = ({
  children,
  disabled,
  Icon,
  size,
  className,
  ...props
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      className={classNames(
        getClasses({
          classOverrides: className,
          outline: true,
          disabled,
          size,
        }),
        `flex items-center`,
      )}
      type="button"
      {...props}
    >
      {Icon && (
        <Icon
          height={size === 'small' ? '20' : '24'}
          className={classNames(
            'inline fill-current',
            size === 'small' ? 'mr-8' : 'mr-16',
          )}
        />
      )}
      {children}
    </button>
  );
};
