import { useEffect } from 'react';

const useBodyReset = () => {
  useEffect(() => {
    const isSafari = navigator.userAgent.indexOf('Safari') > -1;
    if (isSafari) {
      document.body.style.overflowY = 'scroll';
      const timeOut = setTimeout(
        () => (document.body.style.overflowY = 'auto'),
        500,
      );

      return () => {
        clearTimeout(timeOut);
      };
    }
    return undefined;
  }, []);
};

export default useBodyReset;
